/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useReducer, createContext } from "react";
import { HandDocument, handInitDoc } from '../types/pj1dbApiHand'
import { FingertipDocument, fingertipInitDoc } from '../types/pj1dbApiFingertip'
import {
  FormHandSearchCond,
  FormHandAttr,
  handInitInputs,
} from '../types/handForm'

import {
  FormFingertipSearchCond,
  FormFingertipAttr,
  fingertipInitInputs
} from '../types/fingertipForm'

// useReducerで生成する「参照用のstate」の型
type DataStoreType = {
  tabIndex:number;
  handListPage:{
    searchCond:FormHandSearchCond;
    handList:HandDocument[];
  };
  handDetailPage:{
    info:HandDocument;
  };
  fingertipListPage:{
    searchCond:FormFingertipSearchCond;
    fingertipList:FingertipDocument[];
  };
  fingertipDetailPage:{
    info:FingertipDocument;
  };
  registPage:{
    regType:string;
    regModeHand:boolean;
    handPanel:FormHandAttr;
    regModeFingertip:boolean;
    fingertipPanel:FormFingertipAttr;
  };
};
export const initialState: DataStoreType = {
  tabIndex: 0,
  handListPage: {
    searchCond:{
      keyword:"",
      listCount:0,
      sortOrder:"",
    },
    handList:[],
  },
  handDetailPage: {
    info:handInitDoc,
  },
  fingertipListPage:{
    searchCond:{
      listCount:0,
      sortOrder:"",
    },
    fingertipList:[],
  },
  fingertipDetailPage:{
    info:fingertipInitDoc,
  },
  registPage:{
    regType:"hand",
    regModeHand:true,
    handPanel: handInitInputs,
    regModeFingertip:true,
    fingertipPanel: fingertipInitInputs,
  }
};

// dispatch関数の第2引数に渡す「action」の型
type ReducerActionType = {
  type: string;
  payload: any;
};
// createContext()のデフォルト値オブジェクトにasで割り当てる。
type DataStoreContextType = {
  state: DataStoreType;
  // dispatchの引数オブジェクトの型を、React.Dispatch<XXXXX> に定義する。
  dispatch: React.Dispatch<ReducerActionType>;
};

// reducer関数：更新用dispatchトリガーで、stateを更新する処理。
// 引数:   1.state 2.action(dispatch関数の引数)
// 戻り値: 更新後の新しいstate
export const CONTEXT_ACTION_TYPE = {
  TAB: "SET_TAB_INDEX",
  HAND_LIST: "SET_HAND_LIST_PAGE",
  HAND_DETAIL: "SET_HAND_DETAIL_PAGE",
  FINGERTIP_LIST: "SET_FINGERTIP_LIST_PAGE",
  FINGERTIP_DETAIL: "SET_FINGERTIP_DETAIL_PAGE",
  REGIST: "SET_REGIST_PAGE",
} as const;
const reducerFunc = (state: DataStoreType, action: ReducerActionType) => {
  // action.typeの値で更新内容を切り替える。
  switch (action.type) {
    case CONTEXT_ACTION_TYPE.TAB:
      return {
        ...state,
        tabIndex: action.payload,
      };
    case CONTEXT_ACTION_TYPE.HAND_LIST:
      return {
        ...state,
        handListPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.HAND_DETAIL:
      return {
        ...state,
        handDetailPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.FINGERTIP_LIST:
      return {
        ...state,
        fingertipListPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.FINGERTIP_DETAIL:
      return {
        ...state,
        fingertipDetailPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.REGIST:
      return {
        ...state,
        registPage: action.payload,
      };
    // 更新前のstateをそのまま返す。
    default:
      return state;
  }
};


// createContextはReactフックではないため、コンポーネント外で使用可能
// as でオブジェクトの型チェックをクリアする。
export const DataStoreContext = createContext({} as DataStoreContextType);

export function DataStoreContextProvider(props:any): JSX.Element {
  // useReducerで生成した「参照用state」と「更新用dispatch」を、contextに渡す。
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  return (
    <DataStoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </DataStoreContext.Provider>
  );
}