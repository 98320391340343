import * as React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'
import { Link } from "react-router-dom"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const userSettings: { [key: string]: string } = {
  logout: "ログアウト"
};

export default function MainHeader() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const getLoginName = () => `${String(user?.attributes?.given_name)} ${String(user?.attributes?.family_name)}`;
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const clickUserMenu = (key:string) => {
    switch (key) {
      case "logout":
        signOut();
        break;
      default:
    }
  };

  return (
    <AppBar position="static" sx={{ color: 'text.primary', backgroundColor: 'white' , boxShadow: 'none'}}>
      <Toolbar disableGutters sx={{ px:2 , py:1 , minHeight: 'unset !important'}}>
        <Link to='/'>
          <img src="/robocip_logo.svg" className="top-logo" alt="利用者UI" />
        </Link>
        <Typography variant="h5" sx={{ml: 2, fontWeight: 500 , flexGrow: 1}} >
          利用者UI
        </Typography>
        <Box sx={{ flexGrow:0 , mr:1}}>
          <Typography variant="subtitle1">
            {getLoginName()}さん
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="ユーザーメニューを開く">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <AccountCircleIcon fontSize='large'/>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {Object.keys(userSettings).map((key) => (
              <MenuItem key={key} onClick={() => {
                handleCloseUserMenu();
                clickUserMenu(key);
              }}>
                <Typography variant="button" textAlign="center">{userSettings[key]}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}