import {Box, Card, Container, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useState} from "react";
import Pjdb1ImputEx from "../../../../components/inputs/Pjdb1ImputEx";
import {handleInputChange, handleInputChangeFiles} from "../../../../utils/formUtil";
import InstanceDescription from "./fields/InstanceDescription";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import WithLabel, {WithLabelSpacingWrapper} from "./fields/WithLabel";
import {WorkClassDocument,} from "../../types/pj1dbApiWork";
import {workAttrList} from "../../types/workForm";
import {
  registerWorkInstance,
  registerWorkModel,
  uploadModelFiles
} from "../../../../utils/awsAmplifyUtil";
import useMutationHelper from "../../hooks/useMutationHelper";
import Model from "./fields/Model";
import FileType from "./fields/FileType";
import When from "./fields/When";
import Who from "./fields/Who";
import {WorkModelType} from "../../../../constants/constants";

export const editFormInitInputs: EditFormWorkAttr = {
  [workAttrList.modelFile.key]: null,
}

export interface EditFormWorkAttr {
  [workAttrList.description.key]?: string;
  [workAttrList.modelType.key]?: WorkModelType;
  [workAttrList.modelFile.key]: File[] | null;
  [workAttrList.fileType.key]?: string;
  [workAttrList.when.key]?: string;
  [workAttrList.who.key]?: string;
}

export interface EditFormProps {
  work: WorkClassDocument;
  inputs: EditFormWorkAttr
  setInputs: React.Dispatch<React.SetStateAction<EditFormWorkAttr>>;
  // eslint-disable-next-line react/require-default-props
  onError?: (err: unknown) => void;
  // eslint-disable-next-line react/require-default-props
  onSuccess?: () => void;
}

/**
 * ワークのクラス情報編集とインスタンス、モデルの登録を行うフォーム
 * @param props
 * @constructor
 */
export default function EditForm(props: EditFormProps) {
  const {
    updateWork,
    modelFileErr,
    ConfirmDialog,
    formRef,
    clear,
    isClear,
    isLoading,
  } = useEditFormHelper(props);

  return (
    <>
      <ConfirmDialog
        title='ワーク情報登録'
        message='ワーク情報を登録します。入力内容に間違いがないか確認してください。'
        confirmButton='登録'
        confirmButtonStyle="primary"
      />

      <Container maxWidth="lg">
        <Card sx={{p: 3}}>
          <Grid container spacing={3} alignItems="flex-end" justifyContent='space-between' sx={{mb: 2}}>
            <Grid>
              <Typography variant="h6">商品/部品登録</Typography>
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            {props.work.attr[workAttrList.nameJp.key] && (
              <WithLabel label={workAttrList.nameJp.label}>
                <WithLabelSpacingWrapper>
                  {props.work.attr[workAttrList.nameJp.key]}
                </WithLabelSpacingWrapper>
              </WithLabel>
            )}

            {props.work.attr[workAttrList.nameEn.key] && (
              <WithLabel label={workAttrList.nameEn.label}>
                <WithLabelSpacingWrapper>
                  {props.work.attr[workAttrList.nameEn.key]}
                </WithLabelSpacingWrapper>
              </WithLabel>
            )}

            {props.work.attr[workAttrList.code.key] && (
              <WithLabel label={workAttrList.code.label}>
                <WithLabelSpacingWrapper>
                  {props.work.attr[workAttrList.code.key]}
                </WithLabelSpacingWrapper>
              </WithLabel>
            )}

            {props.work.attr[workAttrList.contentWeight.key] && (
              <WithLabel label={workAttrList.contentWeight.label}>
                <WithLabelSpacingWrapper>
                  {props.work.attr[workAttrList.contentWeight.key]} g
                </WithLabelSpacingWrapper>
              </WithLabel>
            )}

            {props.work.attr[workAttrList.contentVolume.key] && (
              <WithLabel label={workAttrList.contentVolume.label}>
                <WithLabelSpacingWrapper>
                  {props.work.attr[workAttrList.contentVolume.key]} ℓ
                </WithLabelSpacingWrapper>
              </WithLabel>
            )}

            <InstanceDescription value={props.inputs[workAttrList.description.key]}
                                 onChange={(e) => (handleInputChange<EditFormWorkAttr>(e, props.inputs, props.setInputs))}
                                 isClear={isClear}/>

            <Model modelType={props.inputs[workAttrList.modelType.key] ?? ''}
                   files={props.inputs[workAttrList.modelFile.key]}
                   fileErr={modelFileErr.err}
                   fileErrMsg={modelFileErr.errMsg}
                   onChangeModelType={(e) => (handleInputChange<EditFormWorkAttr>(e, props.inputs, props.setInputs))}
                   onChangeFile={(name, value) => (handleInputChangeFiles<EditFormWorkAttr>(name, value, props.inputs, props.setInputs))}
                   required
                   isClear={isClear}/>

            <FileType value={props.inputs[workAttrList.fileType.key] ?? ''}
                      onChange={(e) => (handleInputChange<EditFormWorkAttr>(e, props.inputs, props.setInputs))}
                      modelType={props.inputs[workAttrList.modelType.key]}
                      required
                      isClear={isClear}/>

            <When value={props.inputs[workAttrList.when.key]}
                  onChange={(e) => (handleInputChange<EditFormWorkAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}/>

            <Who value={props.inputs[workAttrList.who.key]}
                 onChange={(e) => (handleInputChange<EditFormWorkAttr>(e, props.inputs, props.setInputs))}
                 isClear={isClear}/>
          </form>
        </Card>
      </Container>

      <Box component="section" sx={{textAlign: "center"}}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={clear}
        />
        <Pjdb1Button
          label="ワーク情報を登録"
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={() => {
            updateWork().catch(console.error)
          }}
        />
      </Box>
    </>
  )
}

function useEditFormHelper(params: EditFormProps) {
  const [modelFileErr, setModelFileErr] = useState({err: false, errMsg: ""});
  const {makeMutation, ...helper} = useMutationHelper({
    onClear: () => {
      params.setInputs({
        ...editFormInitInputs,
        ...params.work.attr,
      });
    }
  });

  const updateWork = makeMutation({
    onCompleted: () => {
      if (params.onSuccess) {
        params.onSuccess();
      } else {
        helper.notification.savedMsg();
      }
    },
    onError: (err) => {
      helper.handleError({error: err, message: '更新処理が失敗しました。'});
      params.onError?.(err);
    },
    validate: () => {
      if (!params.inputs[workAttrList.modelFile.key]) {
        const msg = "ファイルを選択してください。";
        helper.notification.errorMsg(msg);
        setModelFileErr({err: true, errMsg: msg});
        return false;
      }
      setModelFileErr({err: false, errMsg: ""});
      return true;
    },
    callback: async () => {
      // ファイルアップロード
      const inputModelFiles = params.inputs[workAttrList.modelFile.key]
      const resFile = await uploadModelFiles(inputModelFiles ?? []);
      if (!resFile.isSuccess) {
        helper.notification.errorMsg("ファイルアップロード処理が失敗しました。");
        return false;
      }

      const instanceResult = await registerWorkInstance({
        version: 'work-user',
        classId: params.work.attr[workAttrList.classId.key],
        [workAttrList.description.key]: params.inputs[workAttrList.description.key],
      })
      if (!instanceResult.isSuccess) {
        return helper.handleError({error: instanceResult.err, message: '更新処理が失敗しました。'});
      }

      const modelResult = await registerWorkModel({
        version: 'work-user',
        s3Uri: resFile.data,
        bulk: false, // TODO: モデル数が1つの場合はfalse、複数の場合はtrueに設定するがどのように判定するか確認が必要
        scale: 1,
        changeYZ: false,
        instanceId: instanceResult.data,
        [workAttrList.fileType.key]: params.inputs[workAttrList.fileType.key] as string,
        [workAttrList.when.key]: params.inputs[workAttrList.when.key] ? new Date(params.inputs[workAttrList.when.key] as string).getTime() : undefined,
        [workAttrList.who.key]: params.inputs[workAttrList.who.key] as string,
      })
      if (!modelResult.isSuccess) {
        return helper.handleError({error: modelResult.err, message: '更新処理が失敗しました。'});
      }

      return true;
    },
  });

  return {
    updateWork,
    modelFileErr,
    setModelFileErr,
    ...helper,
  }
}
