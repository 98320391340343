import {Dict, RobocipDbDocument} from "./pj1dbApi";
import {WorkInputFileType, WorkModelType} from "../../../constants/constants";

// --------------------------------------------------------------------------------------
/**
 * work APIのレスポンス型の定義
 */
export type Hierarchy = "model" | "instance" | "class";

interface DirectionField {
  theta: number;
  phi: number;
}

export interface XYZ {
  x: number;
  y: number;
  z: number;
}

export interface WorkModelCalc {
  rawCenter: XYZ;
  rawSize: XYZ;
}

export interface WorkModelAttr extends RobocipDbDocument {
  calc: WorkModelCalc;
  "model-id": string;
  "instance-id": string;
  modelType: WorkModelType;
  fileType: WorkInputFileType;
  when: number;
  who: string;
  faceDirection: DirectionField;
  upDirection: DirectionField;
  extra: object;
}

export interface WorkModelPoolError {
  msg: string; // エラー状況を表す文字列
  errorCode: string; // エラー種類を示す文字列
  tb: unknown; // エラー時点のtraceback(=stacktrace)
}

// queued_duplication→ running_duplication → queued_reconstruction → running_ reconstruction → complete, error
export const WORK_MODEL_POOL_STATUS = {
  QUEUED_DUPLICATION: "queued_duplication",
  RUNNING_DUPLICATION: "running_duplication",
  QUEUED_RECONSTRUCTION: "queued_reconstruction",
  RUNNING_RECONSTRUCTION: "running_reconstruction",
  COMPLETE: "complete",
  ERROR: "error",
};

export type WorkModelPoolStatus = typeof WORK_MODEL_POOL_STATUS[keyof typeof WORK_MODEL_POOL_STATUS];

export interface WorkModelPoolAttr extends RobocipDbDocument {
  /**
   * ※「処理途中(in-progress==True)」とは、find_syncの戻り値でmodel[“url”][“obj”]とmodel[“url”][“mtl”]が返す準備が完了するまでの期間をいう。
   * 「処理完了(in-progress==False)」であっても、それ以外のモデルファイルは変換途中でありURLが得られない場合がある。
   * またmodel[“attr”][“calc”]の値も計算中であり得られない場合がある。
   */
  inProgress: boolean;
  status: WorkModelPoolStatus;
  errors: WorkModelPoolError[];
}

export interface WorkInstanceAttr extends RobocipDbDocument {
  calc: Dict<unknown>;
  "instance-id": string;
  "class-id": string;
  description: string;
  extra: object;
}

export interface WorkClassAttr extends RobocipDbDocument {
  calc: Dict<unknown>;
  "class-id": string;
  parent: string;
  nameJp: string;
  nameEn: string;
  code: string;
  contentWeight: number;
  contentVolume: number;
  standingPosture: object;
  extra: object;

  children: string[];
  instances: string[];
}

export interface WorkModelDocument {
  attr: WorkModelAttr;
  url?: WorkModelDocumentUrl;
}

export interface WorkModelDocumentUrl {
  glb: string;
  thumbnail: string;
  obj: ObjUrl
  usdz?: string;
  usdz_with_simulation_data?: string;
}

export interface ObjUrl {
  obj: string;
  mtl: string[];
  image: string[];
}

export interface WorkInstanceDocument {
  attr: WorkInstanceAttr;
  url?: Dict<string>;
}

export interface WorkClassDocument {
  attr: WorkClassAttr;
  url?: Dict<string[]>;
  instanceModelRelation?: Dict<string[]>;
}

export interface ClassTreeDocument {
  attr: WorkClassAttr;
  url: Dict<string>;
  childIdList: string[];
}

export type WorkDbStatusDocument = Dict<
  string | boolean | Dict<string | string[]>
>;

export type WorkAnyDocument =
  | WorkModelDocument
  | WorkInstanceDocument
  | WorkClassDocument;

export type WorkAnyDocumentDict =
  | Dict<WorkModelDocument>
  | Dict<WorkInstanceDocument>
  | Dict<WorkClassDocument>;

export type WorkDocumentByHierarchy<H extends Hierarchy> =
  H extends "class" ? WorkClassDocument :
    H extends "instance" ? WorkInstanceDocument :
      H extends "model" ? WorkModelDocument :
        never;

export type WorkDocumentDictByHierarchy<H extends Hierarchy> = Dict<WorkDocumentByHierarchy<H>>;

// --------------------------------------------------------------------------------------
/**
 * work APIのリクエストパラメータ型の定義
 */
export interface FindParam {
  hierarchy: Hierarchy;

  /*
  versionを省略した場合、安定versionのデータのみを返します。
  versionに"all"を指定した場合、全てのversionのデータを返します。
  versionにversionIDを指定した場合、該当のversionのデータのみを返します。
  versionIDを”_”で連結した文字列を指定した場合、該当する複数のversionのデータのみを返します。
  ※安定versionは、collectionごとにROBOCIP側で管理する
  */
  version?: string;
  deleted?: boolean; // trueの場合、delete APIで削除されたdocumentも返却対象に含める

  queryAttr?: object; // work-model-attrコレクション及びwork-model-calcコレクションにおける指定条件を満たす一部のモデル情報のみを取得したい場合に指定する。
  queryCalc?: object; // query-attrとquery-calcの両方を指定した場合は、両方を満たす（AND条件）モデル情報のみを返す
}

export interface FindWorkClassParam extends Omit<FindParam, 'hierarchy'> {
  classIds?: string[];
}

export interface FindClassTreeParam {
  classId: string;
}

export interface DeleteParam {
  id: string; // model-id／instance-id／class-idのいずれかを指定する
  restore?: boolean; // falseを指定した場合データを削除（soft delete）する。trueを指定した場合データをリストアする
}

export interface RegisterModelRequestPathParam {
  version: string; // モデル属性データの形式versionを識別する文字列（versionID）を指定する。 versionIDは、数字,英字及びハイフン（-）の組み合わせからなる文字列とする（大文字小��字は区別する）
}

export const extractRegisterModelRequestPathParam = (
  param: RegisterModelRequestParam
): RegisterModelRequestPathParam => ({
  version: param.version,
})

export interface RegisterModelRequestQueryParam {
  // s3Uriで「モデルが含まれるフォルダ」を指定する場合（モデル１個の登録）はfalse、s3Uriで「モデルが含まれるフォルダ」の親フォルダを指定する場合（モデル複数の登録）はtrue
  bulk: boolean;
  // 1以外の0より大きい値が指定された場合、modelのスケールをscale倍してからDBに保存する。
  // ※例：mm単位で作成されたobjを入力する場合、0.001を指定することでm単位に変換されて保存される
  scale: number;
  // trueが指定された場合、modelのyz軸を（右手座標系のまま）交換してからDBに保存する。
  changeYZ: boolean;
}

export const extractRegisterModelRequestQueryParam = (
  param: RegisterModelRequestParam
): RegisterModelRequestQueryParam => ({
  bulk: param.bulk,
  scale: param.scale,
  changeYZ: param.changeYZ,
})

export interface RegisterModelRequestBodyParam {
  addInstance?: boolean; // instance-idを指定しない時のみ有効。trueの場合、自動的にInsert Instanceを実行しその結果のインスタンスIDをモデルにセットする
  addClass?: boolean; // addInstanceがtrueの時のみ有効。trueの場合、自動的にInsert Classを実行しその結果のクラスIDをインスタンスにセットする

  instanceId?: string; // このモデルが属するインスタンスを一意に識別するID
  when?: number; // モデルの測定日時  ※DBにデータを投入した時間とは異なる ※UTC基準でUnix epochからの経過時間をミリ秒単位で表した値。
  who?: string; // モデルの測定者 ※DBにデータを投入した人とは異なる場合がある
  s3Uri: string; // ファイルを投入したS3の「フォルダのURI」を記載する（※１）例：s3://robocip-db-data/robocip/
  // ファイルの構成を区別する文字列。“obj” ： S3のフォルダには.objと.mtlが含まれる。“obj-texture” ： S3のフォルダには.objと.mtlとテクスチャ画像が含まれる。“raw-hoge”：S3のフォルダには生データが含まれる。ファイル構成はhoge方式である（「hoge」は任意文字列でwork-rawコレクションのraw-typeに保存する）
  fileType: string;
  groundTruth?: boolean; // 実用的でない手の込んだ測定方法を用いた場合true
}

export const extractRegisterModelRequestBodyParam = (
  param: RegisterModelRequestParam
): RegisterModelRequestBodyParam => ({
  addInstance: param.addInstance,
  addClass: param.addClass,
  instanceId: param.instanceId,
  when: param.when,
  who: param.who,
  s3Uri: param.s3Uri,
  fileType: param.fileType,
  groundTruth: param.groundTruth,
})

export interface RegisterModelRequestParam extends RegisterModelRequestPathParam, RegisterModelRequestQueryParam, RegisterModelRequestBodyParam {
}

export interface RegisterModelPollParam {
  modelId: string; // 処理ステータスを確認したいモデル属性データのID（model-id）※複数指定したい場合は、_で連結すること※モデル属性データのIDは、数字,英字及びハイフン（-）の組み合わせからなる文字列とする（大文字小文字は区別する）
}

export interface UpdateModelParam {
  modelId: string; // モデルを特定するIDを指定する
  instanceId?: string; // このモデルが属するインスタンスを一意に識別するID
  when?: number; // モデルの測定日時  ※DBにデータを投入した時間とは異なる
  who?: string; // モデルの測定者 ※DBにデータを投入した人とは異なる場合がある
  faceDirection?: DirectionField; // モデルファイル座標系において正面方向ベクトルを表すベクトル
  upDirection?: DirectionField; // モデルファイル座標系において上方向ベクトルを表すベクトル
  extra?: object; // 任意のキーバリューからなる属性値
}

export interface RegisterInstanceParam {
  version: string; // モデル属性データの形式versionを識別する文字列（versionID）を指定する。 versionIDは、数字,英字及びハイフン（-）の組み合わせからなる文字列とする（大文字小文字は区別する）
  classId?: string; //  属するクラスを示すclassID
  description?: string; // このインスタンスを特定する為の参考文字列
  extra?: object; // 任意のキーバリューからなる属性値
}

export interface UpdateInstanceParam {
  // インスタンスを特定するIDを指定する
  instanceId: string;
  // trueの場合：既存のextraと本メソッドで与えられるextraを結合した辞書を保存する。
  //   同じkey名が存在する場合、本メソッドで与えられるextra中の値で上書きする
  // falseの場合：既存のextraは無視し、本メソッドで与えられるextraを保存する。
  append?: boolean;
  // このインスタンスが属するクラスを一意に識別するID
  classId?: string;
  // このインスタンスを特定する為の参考文字列
  description?: string;
  // 任意のキーバリューからなる属性値
  extra?: object;
}

export interface RegisterClassParam {
  version: string; // クラス属性データの形式versionを識別する文字列（versionID）を指定する。 versionIDは、数字,英字及びハイフン（-）の組み合わせからなる文字列とする（大文字小文字は区別する）
  parent?: string; // 属する上位クラスを示すclassIdを指定する
  nameJp: string; // このクラスの名称 *※unicodeを使用可能。運用上英数字のみでの記載も許容。
  nameEn?: string; // このクラスの名称（英語） ※ascii文字のみ許容
  code: string; //
  contentWeight?: number;
  contentVolume?: number;
  extra?: object; // 任意のキーバリューからなる属性値
}

export interface UpdateClassParam {
  // クラスを特定するIDを指定する
  classId: string;
  // trueの場合：既存のextraと本メソッドで与えられるextraを結合した辞書を保存する。
  //   同じkey名が存在する場合、本メソッドで与えられるextra中の値で上書きする
  // falseの場合：既存のextraは無視し、本メソッドで与えられるextraを保存する。
  append?: boolean;
  // 属する上位クラスを一意に識別するID
  parent?: string;
  // このクラスの名称（日本語）
  nameJp?: string;
  // このクラスの名称（英語）
  nameEn?: string;
  // 商品/部品の一意のコード
  code?: string;
  // 任意のキーバリューからなる属性値
  extra?: object;
}

export interface InsertClassFamilyParam {
  version: string; // モデル属性データの形式versionを識別する文字列（versionID）を指定する。 versionIDは、数字,英字及びハイフン（-）の組み合わせからなる文字列とする（大文字小文字は区別する）
  nameFamily: object; //
}

//--------------------------------------------------------------------------------------
/**
 * レスポンスで得られるdocumentの属性情報
 */

export const defaultHideKeysInCalc = [
  "model-id",
  "instance-id",
  "class-id",
  "s3Key",
  "sha256",
];
export const editableModelFields = [
  "instance-id",
  "when",
  "who",
  "face-direction",
  "up-direction",
  "extra",
];
export const editableInstanceFields = ["class-id", "description", "extra"];
export const editableClassFields = ["parent", "name-jp", "name-en", "extra"];
