/**
 * ハンド一覧検索条件
 */
export interface FormFingertipSearchCond {
  listCount:number,
  sortOrder:string,
}

/**
 * 爪登録フォーム用タイプ
 */
export interface FormFingertipAttr {
  fingertipId: string;
  registrationDate: string;
  registrant: string;
  setFingertip:boolean;
  public: boolean;
  name: string;
  number: string;
  producer: string;
  weight: string;
//  sizeLength: string;
//  sizeWidth: string;
//  sizeHeight: string;
  description: string;
  sources: string[];
  fingertipType: string;
  elasticFingertip: boolean;
  thicknessInner: string;
  thicknessOuter: string;
  file: File|null;
  zipURI: string;
  handIds: string[];
}
// 爪登録フォーム 初期値
export const fingertipInitInputs:FormFingertipAttr = {
  fingertipId: "",
  registrationDate: "",
  registrant: "",
  setFingertip: false,
  public: false,
  name: "",
  number: "",
  producer: "",
  weight: "",
//  sizeLength: "",
//  sizeWidth: "",
//  sizeHeight: "",
  description: "",
  sources: [""],
  fingertipType: "",
  elasticFingertip: false,
  thicknessInner: "",
  thicknessOuter: "",
  file: null,
  zipURI: "",
  handIds: [""],
}