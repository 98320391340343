import { RobocipDbDocument } from "./pj1dbApi";
import { FormFingertipAttr } from './fingertipForm'
import { AttrListRowType } from "./handForm";
import { HAND_FINGER_TYPES } from "../../../constants/constants";

// --------------------------------------------------------------------------------------
/**
 * fingertip APIのレスポンス型の定義
 */

export interface Thickness {
  inner?: number;
  outer?: number;
}

export interface FingertipInfo {
  name: string;
  number: string;
  producer: string;
  weight: number;
//  size: Size;
  setFingertip: boolean;
}
export interface ProductInfo {
  cost?: number;
  releaseDate?: number;
  available?: boolean;
  description?: string;
  source: string[];
}
export interface FingertipShape {
  fingertipType: string;
  elasticFingertip?: boolean;
  thickness: Thickness;
}
export interface HandModel {
  modelFormat: string;
  modelTool: string;
}


export interface FingertipAttr extends RobocipDbDocument {
  _id: string;
  fingertipId: string;
  public: boolean;
  handIds: string[];
  fingertipInfo: FingertipInfo
  productInfo: ProductInfo;
  fingertipShape: FingertipShape;
//  fingertipModel: FingertipModel;
}

export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface FingertipDocument {
  attr: FingertipAttr;
  url?: ObjUrl;
}

// FingertipDocumentの初期値
export const fingertipInitDoc:FingertipDocument = {
  attr: {
    _id: "",
    fingertipId: "",
    public:false,
    handIds:[],
    fingertipInfo: {
      name: "",
      number: "",
      producer: "",
      weight: 0,
      setFingertip: false,
    },
    productInfo: {
      source: [],
    },
    fingertipShape: {
      fingertipType: "",
      thickness: {},
    },
    registrationDate: 0,
    registrant: "",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}


/**
 * 爪API 登録用タイプ
 */
export interface ApiFingertipRegist {
  zipURI: string;
  public: boolean;
  handIds: string[];
  fingertipInfo: FingertipInfo
  productInfo: ProductInfo;
  fingertipShape: FingertipShape;
//  fingertipModel: FingertipModel;
}

export interface ApiFingertipUpdate {
  id:string;
  set:{
    zipURI?: string;
    public?: boolean;
    handIds?: string[];
    fingertipInfo?: FingertipInfo
    productInfo?: ProductInfo;
    fingertipShape?: FingertipShape;
  }
}

// --------------------------------------------------------------------------------------
export function makeApiFingertipRegistFromForm(
  inputs:FormFingertipAttr,zipURI:string
):ApiFingertipRegist{
  return {
    zipURI,
    public: inputs.public,
    handIds: inputs.handIds,
    fingertipInfo: {
      name: inputs.name,
      number: inputs.number,
      producer: inputs.producer,
      weight: parseFloat(inputs.weight),
      // size: {
      //   length: parseFloat(inputs.sizeLength),
      //   width: parseFloat(inputs.sizeWidth),
      //   height: parseFloat(inputs.sizeHeight),
      // },
      setFingertip: inputs.setFingertip,
    },
    productInfo: {
      // cost: 0,
      // releaseDate: 0,
      // available: false,
      description: inputs.description,
      source: inputs.sources,
    },
    fingertipShape: {
      fingertipType: inputs.fingertipType,
      elasticFingertip: inputs.elasticFingertip,
      thickness: {
        inner: parseFloat(inputs.thicknessInner),
        outer: parseFloat(inputs.thicknessOuter),
      },
    },
  }
}

export function makeApiFingertipUpdateFromForm(
  inputs:FormFingertipAttr,argZipURI:string
):ApiFingertipUpdate{
  return {
    id: inputs.fingertipId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      public: inputs.public,
      handIds: inputs.handIds,
      fingertipInfo: {
        name: inputs.name,
        number: inputs.number,
        producer: inputs.producer,
        weight: parseFloat(inputs.weight),
        setFingertip: inputs.setFingertip,
      },
      productInfo: {
        description: inputs.description,
        source: inputs.sources,
      },
      fingertipShape: {
        fingertipType: inputs.fingertipType,
        elasticFingertip: inputs.elasticFingertip,
        thickness: {
          inner: parseFloat(inputs.thicknessInner),
          outer: parseFloat(inputs.thicknessOuter),
        },
      },
    }
  }
}

export function makeFormFingertipAttrFromFingertipDocument(
  info:FingertipDocument
):FormFingertipAttr{
  return {
    fingertipId: info.attr.fingertipId,
    registrationDate:
      info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    setFingertip: info.attr.fingertipInfo.setFingertip,
    public: info.attr.public,
    name: info.attr.fingertipInfo.name,
    number: info.attr.fingertipInfo.number,
    producer: info.attr.fingertipInfo.producer,
    weight: String(info.attr.fingertipInfo.weight),
  //  sizeLength: String(info.attr.fingertipInfo.size.length),
  //  sizeWidth: String(info.attr.fingertipInfo.size.width),
  //  sizeHeight: String(info.attr.fingertipInfo.size.height),
    description: info.attr.productInfo.description ? info.attr.productInfo.description : "",
    sources: info.attr.productInfo.source,
    fingertipType: info.attr.fingertipShape.fingertipType,
    elasticFingertip: info.attr.fingertipShape.elasticFingertip ? info.attr.fingertipShape.elasticFingertip : false,
    thicknessInner: info.attr.fingertipShape.thickness.inner ? String(info.attr.fingertipShape.thickness.inner) : "",
    thicknessOuter: info.attr.fingertipShape.thickness.outer ? String(info.attr.fingertipShape.thickness.outer) : "",
    file: null,
    zipURI: "",
    handIds: info.attr.handIds,
  }
}

// --------------------------------------------------------------------------------------
function getUrlHtml(url:string):string {
  let retHtml = "";
  retHtml = `<span><a href="${url}" style="word-break:break-all;font-size:0.75rem;" target="_blank">${url}</a></span>`;
  return retHtml;
}
export function makeFingertipAttrList(
  info:FingertipDocument,
  isSimplify:boolean
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];

  if(!isSimplify){
    attrList.push({key:"setFingertip", class:"基本情報", attr:"メーカー製か否か",
      value:info.attr.fingertipInfo.setFingertip?"メーカー製":"自作カスタム爪"});
    attrList.push({key:"public", class:"", attr:"公開可否",
      value:info.attr.public?"公開爪":"非公開爪"});
  }
  attrList.push({key:"name", class:isSimplify?"基本情報":"", attr:"製品名", value:info.attr.fingertipInfo.name});
  attrList.push({key:"producer", class:"", attr:"製造者", value:info.attr.fingertipInfo.producer});
  attrList.push({key:"number", class:"", attr:"型番", value:info.attr.fingertipInfo.number });
  if(!isSimplify){
    attrList.push({key:"weight", class:"", attr:"爪質量",
      value : `${info.attr.fingertipInfo.weight.toLocaleString()} kg` });
    attrList.push({key:"description", class:"製品情報", attr:"説明",
      value:String(info.attr.productInfo.description?.replace(/\n/g,'<br>')) });
    let sourceStr = "";
    info.attr.productInfo.source.forEach((src)=>{
      if(sourceStr) sourceStr += "<br />";
      sourceStr += getUrlHtml(src);
    });
    attrList.push({key:"source", class:"", attr:"その他情報", value: sourceStr });
  }
  attrList.push({key:"fingertipType", class:"形状", attr:"爪タイプ", value:
    String(HAND_FINGER_TYPES.find((row)=>(row.key===info.attr.fingertipShape.fingertipType))?.label)
  });
  attrList.push({key:"elasticFingertip", class:"", attr:"なじみ爪か否か",
    value:info.attr.fingertipShape.elasticFingertip?"なじみ爪":"なじみ爪ではない" });
  if(!isSimplify){
    attrList.push({key:"thicknessInner", class:"", attr:"外径把持方向の厚さ",
      value: `${String(info.attr.fingertipShape.thickness.inner?.toLocaleString())} mm`});
    attrList.push({key:"thicknessOuter", class:"", attr:"内径把持方向の厚さ",
      value: `${String(info.attr.fingertipShape.thickness.outer?.toLocaleString())} mm`});
  }
  return attrList;
}