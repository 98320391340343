import { RobocipDbDocument } from "./pj1dbApi";
import { FormHandAttr, AttrListRowType } from './handForm'
import { HAND_POWER_SOURCES, HAND_FINGER_TYPES } from "../../../constants/constants";
import { FingertipDocument } from "./pj1dbApiFingertip";
// --------------------------------------------------------------------------------------
/**
 * hand APIのレスポンス型の定義
 */
/*
export interface Size {
  length: number;
  width: number;
  height: number;
}
*/
export interface Stroke {
  range: number;
  openLengthOuter: number;
  closeLengthOuter: number;
  openLengthInner: number;
  closeLengthInner: number;
}
export interface FingertipDetachableStroke {
  range: number;
  openLength: number;
  closeLength: number;
}
export interface MinMax {
  min?: number;
  max?: number;
}
export interface Communication {
  protocol: string[];
  port: string[];
}

export interface HandInfo {
  name: string;
  number: string;
  producer: string;
  weight: number;
//  size: Size;
  voltage?: number;
  fingertipDetachable?: boolean;
}

export interface ProductInfo {
  cost?: number;
  releaseDate?: number;
  available?: boolean;
  source: string[];
}
export interface HandShape {
  fingertipType: string;
  elasticFingertip?: boolean;
}
export interface HandPerformance {
  stroke: Stroke | FingertipDetachableStroke;
  gripPositionResolution: number;
  gripForceInner: MinMax;
  gripForceOuter: MinMax;
  gripForceResolution: number;
  gripSpeed: MinMax;
  payload: number;
}
export interface HandModel {
  modelFormat: string;
  modelTool: string;
}
export interface InterFace {
  communication: Communication
  mechanic: string[];
  electronic: string[];
  atatchmentIds?: string[];
}

export interface HandAttr extends RobocipDbDocument {
  _id: string;
  handId: string;
  handInfo: HandInfo;
  productInfo: ProductInfo;
  handPowerSource: string[];
  handShape: HandShape;
  handPerformance: HandPerformance;
//  handModel: HandModel;
  interface: InterFace;
}
export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface HandDocument {
  attr: HandAttr;
  url?: ObjUrl;
}

// HandDocumentの初期値
export const handInitDoc:HandDocument = {
  attr: {
    _id: "",
    handId: "",
    handInfo: {
      name: "",
      number: "",
      producer: "",
      weight: 0,
      fingertipDetachable: false,
    },
    productInfo: {
      source: [],
    },
    handPowerSource: [],
    handShape: {
      fingertipType: "",
    },
    handPerformance: {
      stroke: {
        range: 0,
        openLengthOuter: 0,
        closeLengthOuter: 0,
        openLengthInner: 0,
        closeLengthInner: 0,
      },
      gripPositionResolution: 0,
      gripForceInner: {},
      gripForceOuter: {},
      gripForceResolution: 0,
      gripSpeed: {},
      payload: 0,
    },
    interface: {
      communication: {
        protocol: [],
        port: [],
      },
      mechanic: [],
      electronic: [],
    },
    registrationDate: 0,
    registrant: "",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}

// --------------------------------------------------------------------------------------
/**
 * ハンドAPI 登録用タイプ
 */
export interface ApiHandRegist {
  zipURI: string;
  handInfo: HandInfo;
  productInfo: ProductInfo;
  handPowerSource: string[];
  handShape: HandShape;
  handPerformance: HandPerformance;
//  handModel: HandModel;
  interface: InterFace;
}

export interface ApiHandUpdate {
  id:string;
  set:{
    zipURI?: string;
    handInfo?: HandInfo;
    productInfo?: ProductInfo;
    handPowerSource?: string[];
    handShape?: HandShape;
    handPerformance?: HandPerformance;
  //  handModel?: HandModel;
    interface?: InterFace;
  }
}

// --------------------------------------------------------------------------------------
export function makeApiHandRegistFromForm(
  inputs:FormHandAttr,zipURI:string
):ApiHandRegist{
  return {
    zipURI,
    handInfo:{
      name: inputs.name,
      number: inputs.number,
      producer: inputs.producer,
      weight: parseFloat(inputs.weight),
      // size: {
      //   length: parseFloat(props.inputs.sizeLength),
      //   width: parseFloat(props.inputs.sizeWidth),
      //   height: parseFloat(props.inputs.sizeHeight),
      // },
      // voltage: 0,
      fingertipDetachable: inputs.fingertipDetachable,
    },
    productInfo:{
      // cost: 0,
      // releaseDate: 0,
      // available: false,
      source: inputs.sources,
    },
    handPowerSource: inputs.handPowerSources,
    handShape: {
      fingertipType: inputs.fingertipType,
      ...(!inputs.fingertipDetachable ? {elasticFingertip: inputs.elasticFingertip} : {}),
    },
    handPerformance: {
      stroke: {
        range: parseFloat(inputs.range),
        ...(!inputs.fingertipDetachable ?
          {
            openLengthOuter: parseFloat(inputs.openLengthOuter),
            closeLengthOuter: parseFloat(inputs.closeLengthOuter),
            openLengthInner: parseFloat(inputs.openLengthInner),
            closeLengthInner: parseFloat(inputs.closeLengthInner),
          } :
          {
            openLength: parseFloat(inputs.openLength),
            closeLength: parseFloat(inputs.closeLength),
          }),
      },
      gripPositionResolution: parseFloat(inputs.gripPositionResolution),
      gripForceInner: {
        min: parseFloat(inputs.gripForceInnerMin),
        max: parseFloat(inputs.gripForceInnerMax),
      },
      gripForceOuter: {
        ...(inputs.gripForceOuterMin ? {min: parseFloat(inputs.gripForceOuterMin)} : {}),
        ...(inputs.gripForceOuterMax ? {max: parseFloat(inputs.gripForceOuterMax)} : {}),
      },
      gripForceResolution: parseFloat(inputs.gripForceResolution),
      gripSpeed: {
        min: parseFloat(inputs.gripSpeedMin),
        max: parseFloat(inputs.gripSpeedMax),
      },
      payload: parseFloat(inputs.payload),
    },
    interface: {
      communication: {
        protocol: inputs.protocols,
        port: inputs.ports,
      },
      mechanic: inputs.mechanics,
      electronic: inputs.electronics,
    }
  };
}

export function makeApiHandUpdateFromForm(
  inputs:FormHandAttr,argZipURI:string
):ApiHandUpdate{
  return {
    id: inputs.handId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      handInfo:{
        name: inputs.name,
        number: inputs.number,
        producer: inputs.producer,
        weight: parseFloat(inputs.weight),
        fingertipDetachable: inputs.fingertipDetachable,
      },
      productInfo:{
        source: inputs.sources,
      },
      handPerformance: {
        stroke: {
          range: parseFloat(inputs.range),
          ...(!inputs.fingertipDetachable ?
            {
              openLengthOuter: parseFloat(inputs.openLengthOuter),
              closeLengthOuter: parseFloat(inputs.closeLengthOuter),
              openLengthInner: parseFloat(inputs.openLengthInner),
              closeLengthInner: parseFloat(inputs.closeLengthInner),
            } :
            {
              openLength: parseFloat(inputs.openLength),
              closeLength: parseFloat(inputs.closeLength),
            }),
        },
        gripPositionResolution: parseFloat(inputs.gripPositionResolution),
        gripForceInner: {
          min: parseFloat(inputs.gripForceInnerMin),
          max: parseFloat(inputs.gripForceInnerMax),
        },
        gripForceOuter: {
          min: parseFloat(inputs.gripForceOuterMin),
          max: parseFloat(inputs.gripForceOuterMax),
        },
        gripForceResolution: parseFloat(inputs.gripForceResolution),
        gripSpeed: {
          min: parseFloat(inputs.gripSpeedMin),
          max: parseFloat(inputs.gripSpeedMax),
        },
        payload: parseFloat(inputs.payload),
      },
    }
  };
}

export function makeFormHandAttrFromHandDocument(
  info:HandDocument
):FormHandAttr{
  return {
    handId: info.attr.handId,
    registrationDate:
      info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    fingertipDetachable: info.attr.handInfo.fingertipDetachable ? info.attr.handInfo.fingertipDetachable :false,
    name: info.attr.handInfo.name ? info.attr.handInfo.name : "",
    producer: info.attr.handInfo.producer ? info.attr.handInfo.producer : "",
    number: info.attr.handInfo.number ? info.attr.handInfo.number : "",
    weight: String(info.attr.handInfo.weight),
  //  sizeLength: String(info.attr.handInfo.size.length),
  //  sizeWidth: String(info.attr.handInfo.size.width),
  //  sizeHeight: String(info.attr.handInfo.size.height),
    sources: info.attr.productInfo.source,
    handPowerSources: info.attr.handPowerSource,
    fingertipType: info.attr.handShape.fingertipType,
    elasticFingertip:
      info.attr.handShape.elasticFingertip ? info.attr.handShape.elasticFingertip : false,
    payload: String(info.attr.handPerformance.payload),
    range: String(info.attr.handPerformance.stroke.range),
    openLengthOuter:
      "openLengthOuter" in info.attr.handPerformance.stroke ?
      String(info.attr.handPerformance.stroke.openLengthOuter) : "",
    closeLengthOuter:
      "closeLengthOuter" in info.attr.handPerformance.stroke ?
      String(info.attr.handPerformance.stroke.closeLengthOuter) : "",
    openLengthInner:
      "openLengthInner" in info.attr.handPerformance.stroke ?
      String(info.attr.handPerformance.stroke.openLengthInner) : "",
    closeLengthInner:
      "closeLengthInner" in info.attr.handPerformance.stroke ?
      String(info.attr.handPerformance.stroke.closeLengthInner) : "",
    openLength:
      "openLength" in info.attr.handPerformance.stroke ?
      String(info.attr.handPerformance.stroke.openLength) : "",
    closeLength:
      "closeLength" in info.attr.handPerformance.stroke ?
      String(info.attr.handPerformance.stroke.closeLength) : "",
    gripPositionResolution: String(info.attr.handPerformance.gripPositionResolution),
    gripForceInnerMin: String(info.attr.handPerformance.gripForceInner.min),
    gripForceInnerMax: String(info.attr.handPerformance.gripForceInner.max),
    gripForceOuterMin: info.attr.handPerformance.gripForceOuter.min ?
      String(info.attr.handPerformance.gripForceOuter.min):"",
    gripForceOuterMax: info.attr.handPerformance.gripForceOuter.max?
      String(info.attr.handPerformance.gripForceOuter.max):"",
    gripForceResolution: String(info.attr.handPerformance.gripForceResolution),
    gripSpeedMin: String(info.attr.handPerformance.gripSpeed.min),
    gripSpeedMax: String(info.attr.handPerformance.gripSpeed.max),
    protocols: info.attr.interface.communication.protocol,
    ports: info.attr.interface.communication.port,
    mechanics: info.attr.interface.mechanic,
    electronics: info.attr.interface.electronic,
    file: null,
    zipURI: "",
  }
}

// --------------------------------------------------------------------------------------
function getUrlHtml(url:string):string {
  let retHtml = "";
  retHtml = `<span><a href="${url}" style="word-break:break-all;font-size:0.75rem;" target="_blank">${url}</a></span>`;
  return retHtml;
}
export function makeHandAttrList(
  info:HandDocument,
  tipInfo?:FingertipDocument
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];

  attrList.push({key:"name", class:"基本情報", attr:"製品名", value:info.attr.handInfo.name});
  attrList.push({key:"producer", class:"", attr:"製造者", value:info.attr.handInfo.producer});
  attrList.push({key:"number", class:"", attr:"型番", value:info.attr.handInfo.number });
  if(tipInfo===undefined){
    attrList.push({key:"weight", class:"", attr:"ハンド質量",
      value: `${info.attr.handInfo.weight.toLocaleString()} kg`});
  }else{
    // 合成スペック
    const synthesisWeight = info.attr.handInfo.weight + tipInfo.attr.fingertipInfo.weight;
    attrList.push({key:"weight", class:"", attr:"ハンド質量",
      value: `${synthesisWeight.toLocaleString()} kg`});
  }
  attrList.push({key:"fingertipDetachable", class:"", attr:"爪脱着可否",
    value: info.attr.handInfo.fingertipDetachable? "脱着可能" : "脱着不可" });
  let sourceStr = "";
  info.attr.productInfo.source.forEach((src)=>{
    if(sourceStr) sourceStr += "<br />";
    sourceStr += getUrlHtml(src);
  });
  attrList.push({key:"source", class:"製品情報", attr:"その他情報", value: sourceStr });
  attrList.push({key:"handPowerSource", class:"動作原理", attr:"", value:
    info.attr.handPowerSource.map((hps)=>(
      HAND_POWER_SOURCES.find((row)=>(row.key===hps))?.label
    )).join("、")
  });
  attrList.push({key:"fingertipType", class:"形状", attr:"爪タイプ", value:
    String(HAND_FINGER_TYPES.find((row)=>(row.key===info.attr.handShape.fingertipType))?.label)
  });
  if(!info.attr.handInfo.fingertipDetachable){
    attrList.push({key:"elasticFingertip", class:"", attr:"なじみ爪か否か",
      value:info.attr.handShape.elasticFingertip?"なじみ爪":"なじみ爪ではない" });
  }
  attrList.push({key:"payload", class:"性能", attr:"可搬質量",
    value : `${info.attr.handPerformance.payload.toLocaleString()} kg` });
  attrList.push({key:"range", class:"", attr:"ストローク",
    value: `${info.attr.handPerformance.stroke.range.toLocaleString()} mm`});
  if(!info.attr.handInfo.fingertipDetachable){
    attrList.push({key:"openLengthOuter", class:"", attr:"外径把持 爪開寸法",
      value : "openLengthOuter" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.openLengthOuter?.toLocaleString()} mm` : "" });
    attrList.push({key:"closeLengthOuter", class:"", attr:"外径把持 爪閉寸法",
      value : "closeLengthOuter" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.closeLengthOuter?.toLocaleString()} mm` : "" });
    attrList.push({key:"openLengthInner", class:"", attr:"内径把持 爪開寸法",
      value : "openLengthInner" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.openLengthInner?.toLocaleString()} mm` : "" });
    attrList.push({key:"closeLengthInner", class:"", attr:"内径把持 爪閉寸法",
      value : "closeLengthInner" in info.attr.handPerformance.stroke ?
        `${info.attr.handPerformance.stroke.closeLengthInner?.toLocaleString()} mm` : "" });
  }else if(tipInfo===undefined){
      attrList.push({key:"openLength", class:"", attr:"可動部開寸法",
        value : "openLength" in info.attr.handPerformance.stroke ?
          `${info.attr.handPerformance.stroke.openLength?.toLocaleString()} mm` : "" });
      attrList.push({key:"closeLength", class:"", attr:"可動部閉寸法",
        value : "closeLength" in info.attr.handPerformance.stroke ?
          `${info.attr.handPerformance.stroke.closeLength?.toLocaleString()} mm` : "" });
  }else{
    // 合成スペック
    const thicknessInner = tipInfo.attr.fingertipShape.thickness.inner !== undefined ? tipInfo.attr.fingertipShape.thickness.inner : 0;
    const thicknessOuter = tipInfo.attr.fingertipShape.thickness.outer !== undefined ? tipInfo.attr.fingertipShape.thickness.outer : 0;
    // ハンド属性の可動部開寸法から2×爪属性のthickness/innerを引いた値
    let synthesisOpenLengthOuter = "openLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.openLength : 0;
    synthesisOpenLengthOuter -= ( thicknessInner * 2);
    synthesisOpenLengthOuter = synthesisOpenLengthOuter > 0 ? synthesisOpenLengthOuter : 0;
    // ハンド属性の可動部閉寸法から2×爪属性のthickness/innerを引いた値
    let synthesisCloseLengthOuter = "closeLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.closeLength : 0;
    synthesisCloseLengthOuter -= ( thicknessInner * 2);
    synthesisCloseLengthOuter = synthesisCloseLengthOuter > 0 ? synthesisCloseLengthOuter : 0;
    // ハンド属性の可動部開寸法から2×爪属性のthickness/outerを足した値
    let synthesisOpenLengthInner = "openLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.openLength : 0;
    synthesisOpenLengthInner += ( thicknessOuter * 2);
    // ハンド属性の可動部閉寸法から2×爪属性のthickness/outerを足した値
    let synthesisCloseLengthInner = "closeLength" in info.attr.handPerformance.stroke ?
      info.attr.handPerformance.stroke.closeLength : 0;
    synthesisCloseLengthInner += ( thicknessOuter * 2);
    attrList.push({key:"openLengthOuter", class:"", attr:"外径把持 爪開寸法",
      value : `${synthesisOpenLengthOuter?.toLocaleString()} mm` });
    attrList.push({key:"closeLengthOuter", class:"", attr:"外径把持 爪閉寸法",
      value : `${synthesisCloseLengthOuter.toLocaleString()} mm` });
    attrList.push({key:"openLengthInner", class:"", attr:"内径把持 爪開寸法",
      value : `${synthesisOpenLengthInner.toLocaleString()} mm` });
    attrList.push({key:"closeLengthInner", class:"", attr:"内径把持 爪閉寸法",
      value : `${synthesisCloseLengthInner.toLocaleString()} mm` });
  }
  attrList.push({key:"gripPositionResolution", class:"", attr:"把持位置分解能",
    value : `${info.attr.handPerformance.gripPositionResolution.toLocaleString()} mm` });
  attrList.push({key:"gripForceInnerMin", class:"", attr:"外径把持力 最小",
    value : `${String(info.attr.handPerformance.gripForceInner.min?.toLocaleString())} N` });
  attrList.push({key:"gripForceInnerMax", class:"", attr:"外径把持力 最大",
    value : `${String(info.attr.handPerformance.gripForceInner.max?.toLocaleString())} N` });
  if(info.attr.handPerformance.gripForceOuter.min){
    attrList.push({key:"gripForceOuterMin", class:"", attr:"内径把持力 最小",
      value : `${String(info.attr.handPerformance.gripForceOuter.min.toLocaleString())} N` });
  }
  if(info.attr.handPerformance.gripForceOuter.max){
    attrList.push({key:"gripForceOuterMax", class:"", attr:"内径把持力 最大",
      value : `${String(info.attr.handPerformance.gripForceOuter.max.toLocaleString())} N` });
  }
  attrList.push({key:"gripForceResolution", class:"", attr:"把持力分解能",
    value : `${String(info.attr.handPerformance.gripForceResolution.toLocaleString())} N` });
  attrList.push({key:"gripSpeedMin", class:"", attr:"開閉速度 最小",
    value : `${String(info.attr.handPerformance.gripSpeed.min?.toLocaleString())} mm/s` });
  attrList.push({key:"gripSpeedMax", class:"", attr:"開閉速度 最大",
    value : `${String(info.attr.handPerformance.gripSpeed.max?.toLocaleString())} mm/s` });
  attrList.push({key:"protocol", class:"インターフェース", attr:"通信プロトコル",
    value:info.attr.interface.communication.protocol.join("、") });
  attrList.push({key:"port", class:"", attr:"I/O IF",
    value:info.attr.interface.communication.port.join("、") });
  attrList.push({key:"mechanic", class:"", attr:"機械的IF名",
    value:info.attr.interface.mechanic.join("、") });
  attrList.push({key:"electronic", class:"", attr:"電気的IF名",
    value:info.attr.interface.electronic.join("、") });
  return attrList;
}

