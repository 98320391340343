/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, {createContext, useReducer} from "react";
import {WorkClassDocument} from '../types/pj1dbApiWork'
import {FormWorkSearchCond, WORK_FORM_STEP, WorkFormStep,} from '../types/workForm'
import {editFormInitInputs, EditFormWorkAttr} from "../components/forms/EditForm";
import {newFormInitInputs, RegisterFormWorkAttr} from "../components/forms/RegisterForm";
import {WORK_SORT_ORDER} from "../../../constants/constants";

export const REGISTRATION_ACTION_TYPE = {
  NEW: 'new',
  EDIT: 'edit',
} as const;

export type RegistrationActionType = typeof REGISTRATION_ACTION_TYPE[keyof typeof REGISTRATION_ACTION_TYPE];

// useReducerで生成する「参照用のstate」の型
export type DataStoreType = {
  tabIndex: number;
  workListPage: {
    searchCond: FormWorkSearchCond;
    workList: WorkClassDocument[];
  };
  workDetailPage: {
    info: WorkClassDocument | null;
  };
  registPage: {
    step: WorkFormStep;
    registered: boolean;
    searchCond: FormWorkSearchCond;
    workList: WorkClassDocument[];
    selectedWork: WorkClassDocument | null;
    actionType: RegistrationActionType;
    editFormData: EditFormWorkAttr;
    newFormData: RegisterFormWorkAttr;
  };
};
export const initialState: DataStoreType = {
  tabIndex: 0,
  workListPage: {
    searchCond: {
      keyword: "",
      listCount: 0,
      sortOrder: WORK_SORT_ORDER.NAME.value,
    },
    workList: [],
  },
  workDetailPage: {
    info: null,
  },
  registPage: {
    step: WORK_FORM_STEP.WORK_SEARCH,
    registered: false,
    searchCond: {
      keyword: "",
      listCount: 0,
      sortOrder: WORK_SORT_ORDER.NAME.value,
    },
    workList: [],
    selectedWork: null,
    actionType: REGISTRATION_ACTION_TYPE.NEW,
    editFormData: editFormInitInputs,
    newFormData: newFormInitInputs,
  }
};

// dispatch関数の第2引数に渡す「action」の型
type ReducerActionType = {
  type: string;
  payload: any;
};

// createContext()のデフォルト値オブジェクトにasで割り当てる。
type DataStoreContextType = {
  state: DataStoreType;
  // dispatchの引数オブジェクトの型を、React.Dispatch<XXXXX> に定義する。
  dispatch: React.Dispatch<ReducerActionType>;
};

// reducer関数：更新用dispatchトリガーで、stateを更新する処理。
// 引数:   1.state 2.action(dispatch関数の引数)
// 戻り値: 更新後の新しいstate
export const CONTEXT_ACTION_TYPE = {
  TAB: "SET_TAB_INDEX",
  WORK_LIST: "SET_WORK_LIST_PAGE",
  WORK_DETAIL: "SET_WORK_DETAIL_PAGE",
  REGIST: "SET_REGIST_PAGE",
} as const;
const reducerFunc = (state: DataStoreType, action: ReducerActionType) => {
  // action.typeの値で更新内容を切り替える。
  switch (action.type) {
    case CONTEXT_ACTION_TYPE.TAB:
      return {
        ...state,
        tabIndex: action.payload,
      };
    case CONTEXT_ACTION_TYPE.WORK_LIST:
      return {
        ...state,
        workListPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.WORK_DETAIL:
      return {
        ...state,
        workDetailPage: action.payload,
      };
    case CONTEXT_ACTION_TYPE.REGIST:
      return {
        ...state,
        registPage: action.payload,
      };
    // 更新前のstateをそのまま返す。
    default:
      return state;
  }
};


// createContextはReactフックではないため、コンポーネント外で使用可能
// as でオブジェクトの型チェックをクリアする。
export const DataStoreContext = createContext({} as DataStoreContextType);

export function DataStoreContextProvider(props: any): JSX.Element {
  // useReducerで生成した「参照用state」と「更新用dispatch」を、contextに渡す。
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  return (
    <DataStoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </DataStoreContext.Provider>
  );
}
