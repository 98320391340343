import { Link } from "react-router-dom"

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { HAND_URLS, WORK_URLS } from '../constants/constants'

export default function Top() {
  return (
    <Container sx={{py:5}}>

      <Typography variant="subtitle2" sx={{mb:2}}>
        本DBに登録されているデータは研究目的で使用するための仮登録データであり、公表を意図したものではありません。
      </Typography>

      <Box component="section" sx={{ display: 'flex', flexDirection: 'row' , alignContent: 'center'}}>
        <img src ="/icons/category_icon.svg" alt="Robot" style={{width:40,height:40}} />
        <Typography variant="h5" component="span" sx={{ml: 1, fontWeight: 500, lineHeight:'normal'}} >
          対象物の登録・選択
        </Typography>
      </Box>

      <Container sx={{py:3, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        <Link to={WORK_URLS.WORK_LIST.URL} style={{ textDecoration:'none' }}>
          <Card sx={{ width:200, height:200 , m:2 ,display:'flex', justifyContent:'center', alignItems:'center'}}>
            <CardContent>
              <Box>
                <img src ="/icons/database_icon.svg" alt="Hand DB" style={{width:100,height:100}} />
              </Box>
              <Typography variant="h5" color="primary.main" align="center">
                ワークDB
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Container>

      <Box component="section" sx={{ display: 'flex', flexDirection: 'row' , alignContent: 'center'}}>
        <img src ="/icons/robot_icon.svg" alt="Robot" style={{width:45,height:45}} />
        <Typography variant="h5" component="span" sx={{ml: 1, fontWeight: 500, lineHeight:'normal'}} >
          ロボットの登録・選択
        </Typography>
      </Box>

      <Container sx={{py:3, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
        <Link to={HAND_URLS.HAND_LIST.URL} style={{ textDecoration:'none' }}>
          <Card sx={{ width:200, height:200 , m:2 ,display:'flex', justifyContent:'center', alignItems:'center'}}>
            <CardContent>
              <Box>
                <img src ="/icons/database_icon.svg" alt="Hand DB" style={{width:100,height:100}} />
              </Box>
              <Typography variant="h5" color="primary.main" align="center">
                ハンドDB
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Container>

    </Container>
  )
}