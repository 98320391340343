import {useContext} from "react";
import {CONTEXT_ACTION_TYPE, DataStoreContext, DataStoreType} from "../contexts/WorkContext";
import {WORK_FORM_STEP, WorkFormStep} from "../types/workForm";
import SelectWork from "../components/registration/SelectWork";
import WorkForm from "../components/registration/WorkForm";


export default function WorkRegister() {
  const {state, dispatch} = useContext(DataStoreContext);
  const setStep = (step: WorkFormStep, payload: Partial<DataStoreType['registPage']> = {}) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.REGIST,
      payload: {
        ...state.registPage,
        ...payload,
        step,
      }
    });
  }

  return (
    <>
      {state.registPage.step === WORK_FORM_STEP.WORK_SEARCH &&
          <SelectWork
              nextStep={(payload: Partial<DataStoreType['registPage']> = {}) => setStep(WORK_FORM_STEP.WORK_REGIST, payload)}/>}
      {state.registPage.step === WORK_FORM_STEP.WORK_REGIST &&
          <WorkForm
              prevStep={(payload: Partial<DataStoreType['registPage']> = {}) => setStep(WORK_FORM_STEP.WORK_SEARCH, payload)}/>}
    </>
  )
}
