/* eslint-disable react/require-default-props */
/* eslint-disable no-void  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */
import { useState, useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Card,
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import { HAND_POWER_SOURCES, HAND_FINGER_TYPES } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/HandContext";

import {
  ApiHandRegist,
  makeApiHandRegistFromForm,
  ApiHandUpdate,
  makeApiHandUpdateFromForm,
  HandDocument
} from '../types/pj1dbApiHand'
import { FormHandAttr, handInitInputs } from '../types/handForm'

import {handleInputChange,handleInputChangeNameVal,handleInputChangeFile} from "../../../utils/formUtil"
import Pjdb1ImputEx from '../../../components/inputs/Pjdb1ImputEx';
import Pjdb1TextField from '../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList, {TextFieldListDescription} from '../../../components/inputs/Pjdb1TextFieldList';
import Pjdb1CheckBox from '../../../components/inputs/Pjdb1CheckBox';
import Pjdb1SelectCheck from '../../../components/inputs/Pjdb1SelectCheck';
import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1FileSelect  from '../../../components/inputs/Pjdb1FileSelect';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';

import {ResponseType, fileUploade, registHand, updateHand, findHand} from '../../../utils/awsAmplifyUtil'

interface Props {
  regMode: boolean;
  setRegMode: React.Dispatch<React.SetStateAction<boolean>>;
  inputs: FormHandAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormHandAttr>>;
}
export default function HandRegistePanel(props:Props) {
  const { savedMsg, updatedMsg, infoMsg ,errorMsg } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();
  const { state, dispatch } = useContext(DataStoreContext);

  const formRef = useRef<HTMLFormElement>(null);

  const [fileErr,setFileErr] = useState({err:false,errMsg:""});

  const clearProc = () => {
    setIsLoading(true);
    props.setRegMode(true);
    props.setInputs({...props.inputs,...handInitInputs});
    setIsClear(true); // 各入力コントロールのerrを初期化
    infoMsg("クリアしました。");
    setIsLoading(false);
  }
  // isClearの戻し処理
  useEffect(()=>{
    if(isClear) setIsClear(false);
  },[isClear]);


  // 更新後のcontextデータ反映
  const updateContextData = async () => {
    // 画像差し替え等もあるのでデータ取得
    const cond = { "query":{"handId":props.inputs.handId} };
    const result = await findHand(cond);
    if(!result.isSuccess) return;
    if(result.data && Object.entries(result.data).length > 0){
      const newData:HandDocument = Object.entries(result.data).map(([,handDoc])=>(handDoc))[0];
      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info: newData} });
      let newList:HandDocument[] = state.handListPage.handList;
      newList = newList.map(row=>row.attr.handId===props.inputs.handId?newData:row);
      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_LIST ,
        payload: {...state.handListPage, handList:newList} });
    }
  }

  const registData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // ファイルの選択状態
      if (!props.inputs.file){
        const msg = "ファイルを選択してください。";
        errorMsg(msg);
        setFileErr({err:true,errMsg:msg});
        return;
      }
      setFileErr({err:false,errMsg:""});

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
      if(!resFile.isSuccess){
        errorMsg("ファイルアップロード処理が失敗しました。");
        return;
      }
      props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));

      // DB登録
      const regData:ApiHandRegist = makeApiHandRegistFromForm(props.inputs, String(resFile.data));
      const resReg:ResponseType<string[]> = await registHand(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }
      savedMsg();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  const updateData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      let sInputFile = "";
      if (props.inputs.file){
        const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
        if(!resFile.isSuccess){
          errorMsg("ファイルアップロード処理が失敗しました。");
          return;
        }
        props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));
        sInputFile = String(resFile.data);
      }

      // DB更新
      const regData:ApiHandUpdate = makeApiHandUpdateFromForm(props.inputs, sInputFile);
      const resReg:ResponseType<string[]> = await updateHand(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }

      updatedMsg();

      void updateContextData();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <Card sx={{p:3}}>

          <Grid container spacing={3} alignItems="flex-end" sx={{mb:2}}>
            <Grid width="150px">
              <Typography variant="h6">ハンド情報</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              { !props.regMode &&
                <Typography variant="body2" sx={{color:"gray"}}>
                  登録日時（登録者）：{`${props.inputs.registrationDate}（${props.inputs.registrant}）`}
                </Typography>
              }
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>基本情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>爪脱着可否</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>
                    <Pjdb1CheckBox
                      label="爪の脱着が可能"
                      name="fingertipDetachable" value={props.inputs.fingertipDetachable}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      toolTip="爪の脱着が可能なハンドの場合にチェック"
                    />
                  </Grid>
                </Grid>

                <Pjdb1TextField
                  label="製品名"
                  name="name" value={props.inputs.name}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製造者（メーカー名など）"
                  name="producer" value={props.inputs.producer}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製品の型番"
                  name="number" value={props.inputs.number}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="ハンド質量"
                  name="weight" value={props.inputs.weight}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="kg"
                  toolTip={<span>半角数値<br/>{props.inputs.fingertipDetachable?"ハンドと爪の総質量":"ハンドの質量(爪質量は含まない)"}</span>}
                />

{/*
                <Grid container spacing={2} alignItems="start">
                  <Grid width="90px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>干渉空間</InputLabel>
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="奥行"
                      name="sizeLength" value={props.inputs.sizeLength}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      required unit="mm"
                    />
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="幅"
                      name="sizeWidth" value={props.inputs.sizeWidth}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      required unit="mm"
                    />
                  </Grid>
                  <Grid width="240px">
                    <Pjdb1TextField
                      label="高さ"
                      name="sizeHeight" value={props.inputs.sizeHeight}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      required unit="mm"
                      toolTip="半角数値"
                    />
                  </Grid>
                </Grid>
*/}

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>製品情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>その他情報</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>
                    <Pjdb1TextFieldList
                      label="URL(製品HP, カタログのリンクなど)"
                      name="sources" value={props.inputs.sources}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required xs={10}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />
                  </Grid>
                </Grid>


              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>動作原理</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1SelectCheck
                  name="handPowerSources" value={props.inputs.handPowerSources}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  checks={HAND_POWER_SOURCES.map((row)=>({chkKey:row.key,chkLbl:row.label}))}
                  required
                  toolTip="１つ以上チェック"
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>形状</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1RadioGroup
                  name="fingertipType" value={props.inputs.fingertipType}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  radios={HAND_FINGER_TYPES.map((row)=>({btnKey:row.key,btnLbl:row.label}))}
                  disabledRadios={["k","s"]}
                  required
                  toolTip="爪の種類を選択"
                />

                { !props.inputs.fingertipDetachable &&
                <Pjdb1CheckBox
                  label="なじみ爪"
                  name="elasticFingertip" value={props.inputs.elasticFingertip}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  toolTip="なじみ爪の場合にチェック"
                />
                }

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>性能</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextField
                  label="可搬質量"
                  name="payload" value={props.inputs.payload}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="kg"
                  toolTip="半角数値"
                />

                <Pjdb1TextField
                  label="ストローク"
                  name="range" value={props.inputs.range}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="mm"
                  toolTip={
                    <Grid container spacing={1} alignItems="start">
                      <Grid>
                        <img src="/images/handdb/tooltip.range.png" style={{width:"320px"}} alt="ストローク"/>
                      </Grid>
                      <Grid>
                        <span>半角数値<br />最大開幅と最小開幅の差<br />※基準点によらない</span>
                      </Grid>
                    </Grid>
                  }
                />

                { !props.inputs.fingertipDetachable &&
                  <>
                    <Grid container spacing={2} alignItems="start">
                      <Grid width="90px">
                        <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>外径把持</InputLabel>
                      </Grid>
                      <Grid width="250px">
                        <Pjdb1TextField
                          label="爪開寸法"
                          name="openLengthOuter" value={props.inputs.openLengthOuter}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                          toolTip={
                            <Grid container spacing={1} alignItems="start">
                              <Grid>
                                <img src="/images/handdb/tooltip.openLengthOuter.png" style={{width:"160px"}} alt="爪開寸法"/>
                              </Grid>
                              <Grid>
                                <span>半角数値<br />最大開時の把持部間の距離</span>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                      <Grid width="250px">
                        <Pjdb1TextField
                          label="爪閉寸法"
                          name="closeLengthOuter" value={props.inputs.closeLengthOuter}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                          toolTip={
                            <Grid container spacing={1} alignItems="start">
                              <Grid>
                                <img src="/images/handdb/tooltip.closeLengthOuter.png" style={{width:"160px"}} alt="爪閉寸法"/>
                              </Grid>
                              <Grid>
                                <span>半角数値<br />最大閉時の把持部間の距離<br />※0が最小値</span>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="start">
                      <Grid width="90px">
                        <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>内径把持</InputLabel>
                      </Grid>
                      <Grid width="250px">
                        <Pjdb1TextField
                          label="爪開寸法"
                          name="openLengthInner" value={props.inputs.openLengthInner}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                          toolTip={
                            <Grid container spacing={1} alignItems="start">
                              <Grid>
                                <img src="/images/handdb/tooltip.openLengthInner.png" style={{width:"160px"}} alt="爪開寸法"/>
                              </Grid>
                              <Grid>
                                <span>半角数値<br />最大開時の把持部間の距離</span>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                      <Grid width="250px">
                        <Pjdb1TextField
                          label="爪閉寸法"
                          name="closeLengthInner" value={props.inputs.closeLengthInner}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                          toolTip={
                            <Grid container spacing={1} alignItems="start">
                              <Grid>
                                <img src="/images/handdb/tooltip.closeLengthInner.png" style={{width:"160px"}} alt="爪閉寸法"/>
                              </Grid>
                              <Grid>
                                <span>半角数値<br />最大閉時の把持部間の距離</span>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                }
                { props.inputs.fingertipDetachable &&

                  <Grid container spacing={2} alignItems="start">
                    <Grid width="90px">
                      <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>可動部</InputLabel>
                    </Grid>
                    <Grid width="250px">
                      <Pjdb1TextField
                        label="開寸法"
                        name="openLength" value={props.inputs.openLength}
                        onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                        isClear={isClear}
                        required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                        toolTip={
                          <Grid container spacing={1} alignItems="start">
                            <Grid>
                              <img src="/images/handdb/tooltip.openLength.png" style={{width:"160px"}} alt="開寸法"/>
                            </Grid>
                            <Grid>
                              <span>半角数値<br />最大開時のねじ穴中心間の距離</span>
                            </Grid>
                          </Grid>
                        }
                      />
                    </Grid>
                    <Grid width="250px">
                      <Pjdb1TextField
                        label="閉寸法"
                        name="closeLength" value={props.inputs.closeLength}
                        onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                        isClear={isClear}
                        required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                        toolTip={
                          <Grid container spacing={1} alignItems="start">
                            <Grid>
                              <img src="/images/handdb/tooltip.closeLength.png" style={{width:"160px"}} alt="閉寸法"/>
                            </Grid>
                            <Grid>
                              <span>半角数値<br />最大閉時のねじ穴中心間の距離</span>
                            </Grid>
                          </Grid>
                        }
                      />
                    </Grid>
                  </Grid>

                }

                <Pjdb1TextField
                  label="把持位置分解能"
                  name="gripPositionResolution" value={props.inputs.gripPositionResolution}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="mm"
                  toolTip="半角数値"
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="120px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>外径把持力</InputLabel>
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="最小"
                      name="gripForceInnerMin" value={props.inputs.gripForceInnerMin}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                    />
                  </Grid>
                  <Grid width="240px">
                    <Pjdb1TextField
                      label="最大"
                      name="gripForceInnerMax" value={props.inputs.gripForceInnerMax}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                      toolTip="半角数値"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="120px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>内径把持力</InputLabel>
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="最小"
                      name="gripForceOuterMin" value={props.inputs.gripForceOuterMin}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                    />
                  </Grid>
                  <Grid width="240px">
                    <Pjdb1TextField
                      label="最大"
                      name="gripForceOuterMax" value={props.inputs.gripForceOuterMax}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                      toolTip="半角数値"
                    />
                  </Grid>
                </Grid>

                <Pjdb1TextField
                  label="把持力分解能"
                  name="gripForceResolution" value={props.inputs.gripForceResolution}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="N"
                  toolTip="半角数値"
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="120px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>開閉速度</InputLabel>
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="最小"
                      name="gripSpeedMin" value={props.inputs.gripSpeedMin}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm/s"
                    />
                  </Grid>
                  <Grid width="240px">
                    <Pjdb1TextField
                      label="最大"
                      name="gripSpeedMax" value={props.inputs.gripSpeedMax}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm/s"
                      toolTip="半角数値"
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>インターフェース</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="150px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>通信・制御IF名</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    <Pjdb1TextFieldList
                      label="通信プロトコル(Modbus RTU(USB接続時), シリアル通信,  Ethenet/IP(Eth接続時) など)"
                      name="protocols" value={props.inputs.protocols}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      required xs={10}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />
                    <Pjdb1TextFieldList
                      label="制御IF(ROS, 独自制御方式など)"
                      name="ports" value={props.inputs.ports}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      required xs={10}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                  </Grid>
                </Grid>

                <Pjdb1TextFieldList
                  label="機械的IF(ISO-9409-***など)"
                  name="mechanics" value={props.inputs.mechanics}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="電気的IF(USB2.0, RS485, Ethなど)"
                  name="electronics" value={props.inputs.electronics}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px" alignItems="start">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ファイル</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1FileSelect
                  label="ファイル"
                  name="file" value={props.inputs.file ? [props.inputs.file]:[]}
                  onChange={(name,value)=>(handleInputChangeFile<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  accept={{'application/zip': ['.zip']}} multiple={false}
                  err={fileErr.err} errMsg={fileErr.errMsg}
                  xs={8}
                  toolTip={
                    <span>
                      「.zip」ファイルのみアップロード可能<br />
                      zip内フォルダ構成<br />
                      ┣&nbsp;image<br />
                      ┃┣&nbsp;main<br />
                      ┃┃┗&nbsp;メイン画像  ※必須、1枚<br />
                      ┃┗&nbsp;sub<br />
                      ┃&nbsp;&nbsp;┗ サブ画像  任意、0枚以上<br />
                      ┗&nbsp;urdf<br />
                      &nbsp;&nbsp;┗ URDFファイル  任意、1枚
                    </span>
                  }
                />

              </Grid>
            </Grid>
          </form>

        </Card>
      </Container>

      <Box component="section" sx={{ textAlign:"center" }}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={clearProc}
        />
        <Pjdb1Button
          label={`製品情報を${props.regMode?'登録':'更新'}`}
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={()=>{
            if(props.regMode){
              void registData();
            }else{
              void updateData();
            }
          }}
        />
      </Box>

      <ConfirmDialog
        title={`${props.regMode?'登録':'更新'}確認`}
        message={`ハンド情報を${props.regMode?'登録':'更新'}しますか？`}
        confirmButton={`${props.regMode?'登録':'更新'}`}
        confirmButtonStyle="primary"
      />

    </>
  );
}