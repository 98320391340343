import {
  Card,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {WorkClassDocument} from "../types/pj1dbApiWork";
import {CONTEXT_ACTION_TYPE, DataStoreContext} from "../contexts/WorkContext";
import {WORK_URLS} from "../../../constants/constants";
import {workAttrList} from "../types/workForm";

export default function WorkDetail() {
  const {state, dispatch} = useContext(DataStoreContext);
  const navigate = useNavigate();
  const work = useWork();
  console.log("WorkDetail: work", work);

  useEffect(() => {
    if (!work) {
      dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: WORK_URLS.WORK_LIST.TAB_IDX});
      navigate(`${WORK_URLS.WORK_LIST.URL}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (!work) {
    console.log("WorkDetail: Work is not found.");
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{maxWidth: "1500px !important"}}>
      <Card sx={{p: 3, mt: 2}}>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid>
            <Typography variant="h6">ワーク詳細</Typography>
          </Grid>
          <Grid sx={{flexGrow: 1}}>
            <Typography variant="body2" sx={{color: "gray"}}>
              登録日時（登録者）：{`${work.attr[workAttrList.created.key] ? new Date(work.attr[workAttrList.created.key]).toLocaleString() : ""}（${work.attr[workAttrList.creator.key]}）`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="flex-start" sx={{mt: 1}}>
          <Grid size={{xs: 12, md: 7}} sx={{pt: "0px !important"}}>
            <Model/>
          </Grid>

          <Grid size={{xs: 12, md: 5}} sx={{pt: "20px !important"}}>
            <Info work={work}/>
          </Grid>
        </Grid>
      </Card>
    </Container>
  )
}

function Model() {
  return (
    <Grid>
      <Typography variant="subtitle1">モデル</Typography>
    </Grid>
  )
}

function Info({work}: { work: WorkClassDocument }) {
  return (
    <>
      <Typography variant="subtitle1">ハンド属性表</Typography>
      <AttributeListTable work={work}/>
    </>
  )
}

function AttributeListTable({work}: { work: WorkClassDocument }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{maxWidth: "100%"}} size="small" aria-label="a dense table">
        <TableHead sx={{bgcolor: "#EEE"}}>
          <TableRow>
            <TableCell sx={{px: 1, py: 0.5}}>属性</TableCell>
            <TableCell sx={{px: 1, py: 0.5}}>属性値</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell width={140} sx={{px: 1, py: 0.5}}>商品/部品名（日本語表記）</TableCell>
            <TableCell sx={{px: 1, py: 0.5}}
                       dangerouslySetInnerHTML={{__html: String(work.attr[workAttrList.nameJp.key])}}/>
          </TableRow>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell width={140} sx={{px: 1, py: 0.5}}>商品/部品名（英語表記）</TableCell>
            <TableCell sx={{px: 1, py: 0.5}}
                       dangerouslySetInnerHTML={{__html: String(work.attr[workAttrList.nameEn.key])}}/>
          </TableRow>
          {/* TODO: Add a attributes */}
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell width={140} sx={{px: 1, py: 0.5}}>TODO</TableCell>
            <TableCell sx={{px: 1, py: 0.5}} dangerouslySetInnerHTML={{__html: String('add a attributes')}}/>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function useWork(): WorkClassDocument | null {
  const urlParams = useParams<{ [WORK_URLS.WORK_DETAIL.ARG_CLASS_ID]?: string }>();
  const {state} = useContext(DataStoreContext);

  if (!urlParams.classId) {
    console.log("useWork: classId is not found.", state);

    return state.workDetailPage.info;
  }

  if (urlParams.classId === state.workDetailPage.info?.attr["class-id"]) {
    console.log("useWork: classId is same.", state);
    return state.workDetailPage.info;
  }

  console.log("useWork: classId is different.", state, urlParams.classId);
  return state.workListPage.workList.find((row) => row.attr["class-id"] === urlParams.classId) ?? null;
}
