export const HAND_URL_PREFIX = "/HandDB/";
export const HAND_URLS = {
  HAND_LIST: {
    TAB_IDX: 0,
    URL: "/HandDB/HandList"
  },
  HAND_DETAIL: {
    TAB_IDX: 1,
    URL: "/HandDB/HandDetail",
    ARG: "handId"
  },
  FINGERTIP_LIST: {
    TAB_IDX: 2,
    URL: "/HandDB/FingertipList"
  },
  FINGERTIP_DETAIL: {
    TAB_IDX: 3,
    URL: "/HandDB/FingertipDetail",
    ARG: "fingertipId"
  },
  REGISTE: {
    TAB_IDX: 4,
    URL: "/HandDB/HandFingertipRegiste",
    ARG_MODE: "mode",
    ARG_ID: "id"
  },
} as const;

export const HAND_POWER_SOURCES = [
  {key: "elePosition", label: "電動(任意位置)"},
  {key: "elePower", label: "電動(任意力)"},
  {key: "airSolo", label: "空圧(単動)"},
  {key: "airMulti", label: "空圧(複動)"},
] as const;
export const HAND_FINGER_TYPES = [
  {key: "2", label: "爪２本"},
  {key: "3", label: "爪３本"},
  {key: "k", label: "吸着"},
  {key: "s", label: "その他"},
] as const;

export const WORK_URL_PREFIX = "/WorkDB/";
export const WORK_URLS = {
  WORK_LIST: {
    TAB_IDX: 0,
    URL: "/WorkDB/WorkList",
  },
  WORK_DETAIL: {
    TAB_IDX: 1,
    URL: "/WorkDB/WorkDetail",
    ARG_CLASS_ID: "classId",
  },
  WORK_REGISTER_LIST: {
    TAB_IDX: 2,
    URL: "/WorkDB/WorkRegisterList",
  },
  WORK_REGISTER: {
    TAB_IDX: 3,
    URL: "/WorkDB/WorkRegister",
    ARG_CLASS_ID: "classId",
    ARG_INSTANCE_ID: "instanceId",
  },
} as const;
export const WORK_MODEL_TYPES = {
  REGISTRATION: {key: "0", label: "作成済みのモデルを登録"},
  CREATION: {key: "1", label: "画像、動画からモデルを自動生成して登録"}, // "画像、動画からモデルを自動生成して登録"については、バックエンドAPI未実装のためダミーとする
} as const;
export type WorkModelType = typeof WORK_MODEL_TYPES[keyof typeof WORK_MODEL_TYPES]["key"];
export const WORK_INPUT_FILE_TYPES = [
  {key: "obj", label: "オブジェクト"},
  {key: "obj-texture", label: "オブジェクトテクスチャ"},
] as const;
export type WorkInputFileType = typeof WORK_INPUT_FILE_TYPES[number]["key"];
export const WORK_SORT_ORDER = {
  NAME: {value: "name", label: "商品/部品名"},
  CODE: {value: "code", label: "JANコード/部品コード"},
  LATEST: {value: "latest", label: "最新"},
} as const;
export type WorkSortOrderValue = typeof WORK_SORT_ORDER[keyof typeof WORK_SORT_ORDER]['value'];
