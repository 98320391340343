import {WorkClassDocument} from "../types/pj1dbApiWork";

export default function pickImageUrl(work: WorkClassDocument, options: {
  defaultUrl?: string;
  sortOrder?: 'latest' | 'oldest';
} = {}): string {
  return Object
      .entries(work.url ?? {})
      .sort(([aKey], [bKey]) => {
        // IDが時系列順になっていることを前提としている
        if (options.sortOrder === 'oldest') {
          return aKey.localeCompare(bKey);
        }

        return bKey.localeCompare(aKey);
      })
      .map(([_, value]) => {
        // 古い順に配列が並んでいることを前提としている
        if (options.sortOrder === 'oldest') {
          return value[0];
        }

        return value[value.length - 1];
      })
      ?.find(() => true)
    ?? options.defaultUrl
    ?? '/'
}
