/**
 * ハンド一覧検索条件
 */
export interface FormHandSearchCond {
  keyword:string,
  listCount:number,
  sortOrder:string,
}

/**
 * ハンド登録フォーム用タイプ
 */
export interface FormHandAttr {
  handId: string;
  registrationDate: string;
  registrant: string;
  fingertipDetachable: boolean;
  name: string;
  producer: string;
  number: string;
  weight: string;
//  sizeLength: string;
//  sizeWidth: string;
//  sizeHeight: string;
  sources: string[];
  handPowerSources: string[];
  fingertipType: string;
  elasticFingertip: boolean;
  payload: string;
  range: string;
  openLengthOuter: string;
  closeLengthOuter: string;
  openLengthInner: string;
  closeLengthInner: string;
  openLength: string;
  closeLength: string;
  gripPositionResolution: string;
  gripForceInnerMin: string;
  gripForceInnerMax: string;
  gripForceOuterMin: string;
  gripForceOuterMax: string;
  gripForceResolution: string;
  gripSpeedMin: string;
  gripSpeedMax: string;
  protocols: string[];
  ports: string[];
  mechanics: string[];
  electronics: string[];
  file: File|null;
  zipURI: string;
}

// ハンド登録フォーム 初期値
export const handInitInputs:FormHandAttr = {
  handId: "",
  registrationDate: "",
  registrant: "",
  fingertipDetachable: false,
  name: "",
  producer: "",
  number: "",
  weight: "",
//    sizeLength: "",
//    sizeWidth: "",
//    sizeHeight: "",
  sources: [""],
  handPowerSources: [],
  fingertipType: "",
  elasticFingertip: false,
  payload: "",
  range: "",
  openLengthOuter: "",
  closeLengthOuter: "",
  openLengthInner: "",
  closeLengthInner: "",
  openLength: "",
  closeLength: "",
  gripPositionResolution: "",
  gripForceInnerMin: "",
  gripForceInnerMax: "",
  gripForceOuterMin: "",
  gripForceOuterMax: "",
  gripForceResolution: "",
  gripSpeedMin: "",
  gripSpeedMax: "",
  protocols: [""],
  ports: [""],
  mechanics: [""],
  electronics: [""],
  file: null,
  zipURI:"",
}

/**
 * ハンド属性表用タイプ
 */
export interface AttrListRowType {
  key: string;
  class: string;
  attr: string;
  value: string;
}
