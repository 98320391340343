import { Amplify } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react'

interface ApiEndpointInfo {
  name: string;
  endpoint: string;
  region: string;
}

export default function configureAmplify() {
  const apiEndpoints: ApiEndpointInfo[] = [];
  apiEndpoints.push({
    name : String(process.env.REACT_APP_AWS_API_NAME_HAND),
    endpoint : String(process.env.REACT_APP_AWS_API_ENDPOINT_HAND),
    region : String(process.env.REACT_APP_AWS_REGION),
  });
  apiEndpoints.push({
    name : String(process.env.REACT_APP_AWS_API_NAME_WORK),
    endpoint : String(process.env.REACT_APP_AWS_API_ENDPOINT_WORK),
    region : String(process.env.REACT_APP_AWS_REGION),
  });

  Amplify.configure({
    Auth: {
      mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    },
    Storage: {
      region: process.env.REACT_APP_AWS_REGION,
      bucket: process.env.REACT_APP_AWS_BUCKET,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    },
    API: {
      endpoints: apiEndpoints,
    },
  });
};

/**
 * cognito user の権限取り出し
 * @returns 
 */
export function useUserRole() {
  let isModifiableRole = false;
  const { user } = useAuthenticator((context) => [context.user]);
  try{
    // console.log(user);
    if(user.getSignInUserSession()?.getIdToken()?.payload){
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cognitoGroups:string[] = user.getSignInUserSession()?.getIdToken()?.payload['cognito:groups'];
      if(cognitoGroups.filter(
        group => group.toLowerCase()==="admin" || group.toLowerCase()==="handmaker").length > 0){
          isModifiableRole = true;
      }
    }
  }catch(e){
    console.log(e);
  }
  return {isModifiableRole};
}
