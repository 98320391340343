/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable no-void  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */
import { useState, useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Card,
  CardMedia,
  CardContent,
  TextField,
  FormControlLabel,
  Switch
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom"

import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog';

import {
  ApiFingertipRegist,
  makeApiFingertipRegistFromForm,
  ApiFingertipUpdate,
  makeApiFingertipUpdateFromForm,
  FingertipDocument,
} from '../types/pj1dbApiFingertip';
import { FormFingertipAttr, fingertipInitInputs } from '../types/fingertipForm';
import { HandDocument } from '../types/pj1dbApiHand';

import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/HandContext";
import { HAND_URLS, HAND_FINGER_TYPES } from '../../../constants/constants'

import {handleInputChange,handleInputChangeNameVal,handleInputChangeFile} from "../../../utils/formUtil"
import Pjdb1ImputEx from '../../../components/inputs/Pjdb1ImputEx';
import Pjdb1TextField from '../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList, {TextFieldListDescription} from '../../../components/inputs/Pjdb1TextFieldList';
import Pjdb1CheckBox from '../../../components/inputs/Pjdb1CheckBox';
import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1FileSelect  from '../../../components/inputs/Pjdb1FileSelect';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import Pjdb1ToolTip from '../../../components/inputs/Pjdb1ToolTip';

import {ResponseType, fileUploade, registFingertip, updateFingertip, findFingertip, findHand} from '../../../utils/awsAmplifyUtil'

interface Props {
  regMode: boolean;
  setRegMode: React.Dispatch<React.SetStateAction<boolean>>;
  inputs: FormFingertipAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormFingertipAttr>>;
}
export default function FingertipRegistePanel(props:Props) {
  const { savedMsg, updatedMsg, infoMsg ,errorMsg } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const { state, dispatch } = useContext(DataStoreContext);

  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();

  const formRef = useRef<HTMLFormElement>(null);

  const [fileErr,setFileErr] = useState({err:false,errMsg:""});

  // 接続可能ハンド選択関連
  const [handList, setHandList] = useState<HandDocument[]>([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [candidateList, setCandidateList] = useState<HandDocument[]>([]);
  const [selectedList, setSelectedList] = useState<HandDocument[]>([]);

  const clearProc = () => {
    setIsLoading(true);
    props.setRegMode(true);
    props.setInputs({...props.inputs,...fingertipInitInputs});
    setIsClear(true); // 各入力コントロールのerrを初期化
    setSelectedList([]);
    infoMsg("クリアしました。");
    setIsLoading(false);
  }
  // isClearの戻し処理
  useEffect(()=>{
    if(isClear) setIsClear(false);
  },[isClear]);

  const registData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // ファイルの選択状態
      if (!props.inputs.file){
        const msg = "ファイルを選択してください。";
        errorMsg(msg);
        setFileErr({err:true,errMsg:msg});
        return;
      }
      setFileErr({err:false,errMsg:""});

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
      if(!resFile.isSuccess){
        errorMsg("ファイルアップロード処理が失敗しました。");
        return;
      }
      props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));

      // DB登録
      const regData:ApiFingertipRegist = makeApiFingertipRegistFromForm(props.inputs, String(resFile.data));
      const resReg:ResponseType<string[]> = await registFingertip(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }
      savedMsg();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  // 更新後のcontextデータ反映
  const updateContextData = async () => {
    // 画像差し替え等もあるのでデータ取得
    const cond = { "query":{"fingertipId":props.inputs.fingertipId} };
    const result = await findFingertip(cond);
    if(!result.isSuccess) return;
    if(result.data && Object.entries(result.data).length > 0){
      const newData:FingertipDocument = Object.entries(result.data).map(([,handDoc])=>(handDoc))[0];
      dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_DETAIL , payload: {info: newData} });
      let newList:FingertipDocument[] = state.fingertipListPage.fingertipList;
      newList = newList.map(row=>row.attr.fingertipId===props.inputs.fingertipId?newData:row);
      dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_LIST ,
        payload: {...state.fingertipListPage, fingertipList:newList} });
    }
  }

  const updateData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      let sInputFile = "";
      if (props.inputs.file){
        const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
        if(!resFile.isSuccess){
          errorMsg("ファイルアップロード処理が失敗しました。");
          return;
        }
        props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));
        sInputFile = String(resFile.data);
      }

      // DB登録
      const regData:ApiFingertipUpdate = makeApiFingertipUpdateFromForm(props.inputs, sInputFile);
      const resReg:ResponseType<string[]> = await updateFingertip(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }

      updatedMsg();

      void updateContextData();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  // ハンド検索（全件）
  const findHandProc = async () => {
    // 爪分離ハンドのみを検索
    const cond = {
      "query":{ "handInfo.fingertipDetachable" : true }
    };
    const result = await findHand(cond);
    if(!result.isSuccess) return;
    // console.log(result);
    if(result.data && Object.entries(result.data).length > 0){
      let handListData:HandDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
      handListData = handListData.sort((a,b) => a.attr.handInfo.name.localeCompare(b.attr.handInfo.name));
      setHandList(handListData);

      setSelectedList(
        handListData.filter((item)=> props.inputs.handIds.includes(item.attr.handId))
      );

    }else{
      setHandList([]);
    }
  }
  useEffect(()=>{
    // 初回のハンド情報検索
    void findHandProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // 製品名変更
  const changeAutoComp = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|null,) => {
    let sKey = "";
    if(event!==null){
      sKey = event.target.value;
      setSearchKeyword(sKey);
    }else{
      sKey = searchKeyword;
    }
    // console.log(sKey);

    let tempCandidateList:HandDocument[] = []
    if(sKey!==""){
      tempCandidateList =
        handList.filter((item)=>
          item.attr.handInfo.name.toUpperCase().includes(String(sKey).toUpperCase()) ||
          item.attr.handInfo.producer.toUpperCase().includes(String(sKey).toUpperCase()) ||
          item.attr.handInfo.number.toUpperCase().includes(String(sKey).toUpperCase())
        );
    }else{
      tempCandidateList = handList;
    }
    setCandidateList(tempCandidateList);
  }


  // 接続可能スイッチ変更
  const changeSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean)=>{
    const name:string = event.target.name ? event.target.name : "";
    let tempHandIds:string[] = props.inputs.handIds;
    if(checked){
      if(!props.inputs.handIds.includes(name)){
        tempHandIds.push(name);
      }
    }else{
      tempHandIds = tempHandIds.filter((handId)=>(handId!==name));
    }
    handleInputChangeNameVal<FormFingertipAttr>("handIds", tempHandIds, props.inputs, props.setInputs)

    setSelectedList(
      handList.filter((item)=> tempHandIds.includes(item.attr.handId))
    );

  };


  return (
    <>
      <Container maxWidth="lg">
        <Card sx={{p:3}}>

          <Grid container spacing={3} alignItems="flex-end" sx={{mb:2}}>
            <Grid width="150px">
              <Typography variant="h6">爪情報</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              { !props.regMode &&
                <Typography variant="body2" sx={{color:"gray"}}>
                  登録日時（登録者）：{`${props.inputs.registrationDate}（${props.inputs.registrant}）`}
                </Typography>
              }
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>基本情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow:1}}>

                <Pjdb1CheckBox
                  label="メーカー製爪"
                  name="setFingertip" value={props.inputs.setFingertip}
                  onChange={(e)=>{
                    handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs);
                    if(e.target.checked){
                      handleInputChangeNameVal<FormFingertipAttr>("public",true, props.inputs, props.setInputs);
                    }
                  }}
                  disabled={!props.regMode}
                  toolTip="メーカー製爪の場合にチェック"
                />

                <Pjdb1CheckBox
                  label="公開爪"
                  name="public" value={props.inputs.public}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  disabled={props.inputs.setFingertip}
                  toolTip="爪を公開しても問題ない場合にチェック"
                />

                <Pjdb1TextField
                  label="製品名"
                  name="name" value={props.inputs.name}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製造者（メーカー名など）"
                  name="producer" value={props.inputs.producer}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製品の型番"
                  name="number" value={props.inputs.number}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="爪質量"
                  name="weight" value={props.inputs.weight}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="kg"
                  toolTip={<span>半角数値<br/>爪の質量</span>}
                />

{/*
                <Grid container spacing={2} alignItems="start">
                  <Grid width="90px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>干渉空間</InputLabel>
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="奥行"
                      name="sizeLength" value={props.inputs.sizeLength}
                      onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                      required unit="mm"
                    />
                  </Grid>
                  <Grid width="200px">
                    <Pjdb1TextField
                      label="幅"
                      name="sizeWidth" value={props.inputs.sizeWidth}
                      onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                      required unit="mm"
                    />
                  </Grid>
                  <Grid width="240px">
                    <Pjdb1TextField
                      label="高さ"
                      name="sizeHeight" value={props.inputs.sizeHeight}
                      onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                      required unit="mm"
                      toolTip="半角数値"
                    />
                  </Grid>
                </Grid>
*/}
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>製品情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow:1}}>

                <Pjdb1TextField
                  label="説明欄"
                  name="description" value={props.inputs.description}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6} multiline rows={4}
                  toolTip={
                    <span>
                      自由記入欄<br />
                      属性値で表せない爪の特徴を必要に応じて記入する<br />
                      爪上の緩衝材の情報など<br />
                    </span>
                  }
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>その他情報</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow:1}}>
                    <Pjdb1TextFieldList
                      label="URL(製品HP, カタログのリンクなど)"
                      name="sources" value={props.inputs.sources}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormFingertipAttr>(name, value, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required xs={10}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>形状</InputLabel>
              </Grid>
              <Grid sx={{flexGrow:1}}>

                <Pjdb1RadioGroup
                  name="fingertipType" value={props.inputs.fingertipType}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  radios={HAND_FINGER_TYPES.map((row)=>({btnKey:row.key,btnLbl:row.label}))}
                  disabledRadios={["k","s"]}
                  required
                  toolTip="爪の種類を選択"
                />

                <Pjdb1CheckBox
                  label="なじみ爪"
                  name="elasticFingertip" value={props.inputs.elasticFingertip}
                  onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  toolTip="なじみ爪の場合にチェック"
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="270px">
                    <Pjdb1TextField
                      label="外径把持方向の厚さ"
                      name="thicknessInner" value={props.inputs.thicknessInner}
                      onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "-?[0-9]*\\.?[0-9]*" }} unit="mm"
                      toolTip={
                        <Grid container spacing={1} alignItems="start">
                          <Grid>
                            <img src="/images/handdb/tooltip.thicknessInner.png" style={{height:"140px"}} alt="外径把持方向の厚さ"/>
                          </Grid>
                          <Grid>
                            <span>
                              半角数値<br />
                              ねじ穴中心から内径把持部までの横方向符号付き距離<br />
                              ※爪形状によって負の値をとる場合もある<br />
                            </span>
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                  <Grid width="270px">
                    <Pjdb1TextField
                      label="内径把持方向の厚さ"
                      name="thicknessOuter" value={props.inputs.thicknessOuter}
                      onChange={(e)=>(handleInputChange<FormFingertipAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "-?[0-9]*\\.?[0-9]*" }} unit="mm"
                      toolTip={
                        <Grid container spacing={1} alignItems="start">
                          <Grid>
                            <img src="/images/handdb/tooltip.thicknessOuter.png" style={{height:"140px"}} alt="内径把持方向の厚さ"/>
                          </Grid>
                          <Grid>
                            <span>
                              半角数値<br />
                              ねじ穴中心から外径把持部までの横方向符号付き距離<br />
                              ※爪形状によって負の値をとる場合もある<br />
                            </span>
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start" sx={{mt:1}}>
              <Grid width="150px" alignItems="start">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ファイル</InputLabel>
              </Grid>
              <Grid sx={{flexGrow:1}}>

                <Pjdb1FileSelect
                  label="ファイル"
                  name="file" value={props.inputs.file ? [props.inputs.file]:[]}
                  onChange={(name,value)=>(handleInputChangeFile<FormFingertipAttr>(name, value, props.inputs, props.setInputs))}
                  accept={{'application/zip': ['.zip']}} multiple={false}
                  err={fileErr.err} errMsg={fileErr.errMsg}
                  xs={8}
                  toolTip={
                    <span>
                      「.zip」ファイルのみアップロード可能<br />
                      zip内フォルダ構成<br />
                      ┣&nbsp;image<br />
                      ┃┣&nbsp;main<br />
                      ┃┃┗&nbsp;メイン画像  ※必須、1枚<br />
                      ┃┗&nbsp;sub<br />
                      ┃&nbsp;&nbsp;┗ サブ画像  任意、0枚以上<br />
                      ┗&nbsp;urdf<br />
                      &nbsp;&nbsp;┗ URDFファイル  任意、1枚
                    </span>
                  }
                />

              </Grid>
            </Grid>
          </form>

          <Box sx={{mt:3.5,borderTop:"1px solid rgba(0, 0, 0, 0.23)"}}>
            <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>接続可能ハンド選択</InputLabel>

            <Grid container spacing={2} alignItems="start" sx={{px:3,py:0,mt:1,alignItems:"stretch"}}>
              <Grid size={{xs:6}} sx={{pt:2}}>
                <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                  <Grid sx={{p:"0 !important"}}>
                    <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>対象ハンド検索</InputLabel>
                  </Grid>
                  <Grid sx={{pt:"0 !important"}}>
                    <Pjdb1ToolTip toolTip={
                      <span>
                        下記に製品名を入力して候補を検索<br />
                        製品カードをクリックでハンド詳細ページに遷移<br />
                        製品カードの接続可能をONにして選択済みハンドに移動
                      </span>
                    } sx={{p:0}} />
                  </Grid>
                </Grid>

                <Box sx={{ width: 0.8 }}>

                  <form onSubmit={(e)=>{
                    e.preventDefault();
                    changeAutoComp(null);
                  }}>
                    <TextField
                      label="製品名、製造者、型番などを入力"
                      name="searchKeyword" value={searchKeyword}
                      variant="outlined" size="small"
                      margin="dense" fullWidth
                      onChange={(e)=>{
                        changeAutoComp(e);
                      }}
                    />
                  </form>

                </Box>

                <Box sx={{ mt:0, pr:4 }}>
                  {candidateList.map(row =>(
                    <Link to={`${HAND_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} key={row.attr.handId} style={{ textDecoration:'none' }}
                    onClick={()=>{
                      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.HAND_DETAIL.TAB_IDX });
                      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:row} });
                    }}>

                      <Card sx={{ display: 'flex', height:100, mt:2 }} >
                        <CardMedia
                          component="img"
                          sx={{ width: 180 }}
                          image={row.url?.image[0]}
                          alt={row.attr.handInfo.name}
                        />
                        <CardContent sx={{p:1}}>
                          <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                            製品名：{row.attr.handInfo.name}
                          </Typography>
                          <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                            製造者：{row.attr.handInfo.producer}
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                name={row.attr.handId}
                                checked={props.inputs.handIds.includes(row.attr.handId)}
                                onChange={changeSwitch}
                              />
                            }
                            label="接続可能"
                            onClick={(e)=>{
                              e.stopPropagation();
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Link>
                  ))}
                </Box>

              </Grid>
              <Grid size={{xs:6}} sx={{borderLeft:"1px solid rgba(0, 0, 0, 0.23)",pt:2,pl:2}}>
                <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>選択済みハンド</InputLabel>

                <Box sx={{ mt:"74px", pr:2 }}>

                  {selectedList.map(row =>(
                    <Link to={`${HAND_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} key={row.attr.handId} style={{ textDecoration:'none' }}
                    onClick={()=>{
                      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.HAND_DETAIL.TAB_IDX });
                      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:row} });
                    }}>
                      <Card sx={{ display: 'flex', height:100, mt:2 }} key={row.attr.handId} >
                        <CardMedia
                          component="img"
                          sx={{ width: 180 }}
                          image={row.url?.image[0]}
                          alt={row.attr.handInfo.name}
                        />
                        <CardContent sx={{p:1}}>
                          <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                            製品名：{row.attr.handInfo.name}
                          </Typography>
                          <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                            製造者：{row.attr.handInfo.producer}
                          </Typography>
                          <FormControlLabel
                            control={
                              <Switch
                                name={row.attr.handId}
                                checked={props.inputs.handIds.includes(row.attr.handId)}
                                onChange={changeSwitch}
                              />
                            }
                            label="接続可能"
                            onClick={(e)=>{
                              e.stopPropagation();
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Link>
                  ))}
                </Box>

              </Grid>
            </Grid>
          </Box>

        </Card>

      </Container>
      <Box component="section" sx={{ textAlign:"center" }}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={clearProc}
        />
        <Pjdb1Button
          label={`製品情報を${props.regMode?'登録':'更新'}`}
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={()=>{
            if(props.regMode){
              void registData();
            }else{
              void updateData();
            }
          }}
        />
      </Box>

      <ConfirmDialog
        title={`${props.regMode?'登録':'更新'}確認`}
        message={`爪情報を${props.regMode?'登録':'更新'}しますか？`}
        confirmButton={`${props.regMode?'登録':'更新'}`}
        confirmButtonStyle="primary"
      />
    </>
  );
}
