/* eslint-disable no-void */
import { useState, useContext, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'
import {
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardMedia,
  InputLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom"

import useNotification from "../../../utils/notificationUtil";
import { findFingertip } from '../../../utils/awsAmplifyUtil'
import { FingertipDocument } from '../types/pj1dbApiFingertip'
import { FormFingertipSearchCond } from '../types/fingertipForm'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/HandContext";
import { HAND_URLS } from '../../../constants/constants'
import { handleInputChangeSelect} from "../../../utils/formUtil"

export default function FingertipList() {
  const { user } = useAuthenticator((context) => [context.user]);
  const { errorMsg } = useNotification();
  const { state, dispatch } = useContext(DataStoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const [ searchCond, setSearchCond] = useState<FormFingertipSearchCond>({
    listCount:0,
    sortOrder:"name",
  });

  const [fingertipList, setFingertipList] = useState<FingertipDocument[]>([]);


  const sortList = (fingertipListData:FingertipDocument[],sortOrder:string):FingertipDocument[] => {
    let list = fingertipListData;
    if(sortOrder==="name"){
      list = list.sort((a,b) => a.attr.fingertipInfo.name.localeCompare(b.attr.fingertipInfo.name));
    }else if(sortOrder==="producer"){
      list = list.sort((a,b) => a.attr.fingertipInfo.producer.localeCompare(b.attr.fingertipInfo.producer));
    }else if(sortOrder==="latest"){
      list = list.sort((a,b) => b.attr.registrationDate - a.attr.registrationDate );
    }
    return list;
  }

  const findFingertipProc = async (initSortOrder="") => {
    setIsLoading(true);
    try {
      const mailaddr = String(user?.attributes?.email);
      const domain = mailaddr.substring(mailaddr.indexOf('@') + 1);
      const cond = {
        "query":{
          "domain": domain
        }
      };
      const result = await findFingertip(cond);
      if(!result.isSuccess){
        errorMsg("検索処理が失敗しました。");
        return;
      }
      // console.log(result);
      if(result.data && Object.entries(result.data).length > 0){
        const fingertipListData:FingertipDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
        const sortOrder = initSortOrder !== "" ? initSortOrder : searchCond.sortOrder;
        setFingertipList(sortList(fingertipListData, sortOrder));
        setSearchCond({sortOrder, listCount:fingertipListData.length});
        dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_LIST, payload: {
          searchCond:{sortOrder,listCount:fingertipListData.length},
          fingertipList:fingertipListData,
        }});
      }else{
        setFingertipList([]);
      }
      // infoMsg("検索しました。");

    } finally {
      setIsLoading(false);
    }
  }

  const changeSortOrder = (event:SelectChangeEvent<string>) => {
    handleInputChangeSelect<FormFingertipSearchCond>(event, "sortOrder",searchCond, setSearchCond);
    setFingertipList(sortList(fingertipList,event.target.value));
    dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_LIST, payload: {
      searchCond:{...searchCond,sortOrder:event.target.value},
      fingertipList,
    }});
  }

  // 検索済みデータの復元
  useEffect(()=>{
    let initSearchSortOrder = "";
    if(state.fingertipListPage.fingertipList.length>0){
      // console.log("HandList","restore from context.",state);
      initSearchSortOrder = state.fingertipListPage.searchCond.sortOrder;
      setSearchCond({...searchCond,sortOrder:initSearchSortOrder});
      // setFingertipList(state.fingertipListPage.fingertipList); 初期検索が入るので復元不要
    }

    // 初期検索
    void findFingertipProc(initSearchSortOrder);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return (

    <Container sx={{p:0, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} maxWidth={false}>

      <Grid container alignItems="end" sx={{mt:2,flexWrap:"nowrap"}} size={{xs:12}}>
        <Grid sx={{flexGrow:1}}>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>同じドメインのユーザーが登録した爪の一覧を表示しています。</Typography>
        </Grid>
        <Grid>
          <Grid container alignItems="center" spacing={2} sx={{flexWrap:"nowrap"}}>
            <Grid>
              <Box component="span">
                {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
              </Box>
            </Grid>
            <Grid>
              <Box component="span">
                <FormControl sx={{minWidth:120}} size="small">
                  <InputLabel id="sort-order-label">並び順</InputLabel>
                  <Select
                    labelId="sort-order-label"
                    name="sortOrder"
                    value={searchCond.sortOrder}
                    label="並び順"
                    onChange={changeSortOrder}
                    sx={{bgcolor:"white"}}
                  >
                    <MenuItem value="name">製品名</MenuItem>
                    <MenuItem value="producer">製造者</MenuItem>
                    <MenuItem value="latest">最新</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignItems="start" spacing={2} sx={{mt:0,pt:2}} size={{xs:12}}>
      {fingertipList.map(row =>(
        <Grid key={row.attr.fingertipId}>
          <Link to={`${HAND_URLS.FINGERTIP_DETAIL.URL}/${row.attr.fingertipId}`} style={{ textDecoration:'none' }}
          onClick={()=>{
            dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.FINGERTIP_DETAIL.TAB_IDX });
            dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_DETAIL , payload: {info:row} });
          }}>
            <Card sx={{ width:240, height:200 }}>
              <CardMedia
                component="img"
                height="145"
                image={row.url?.image[0]}
                alt={row.attr._id}
              />
              <CardContent sx={{py:0.5,px:1,m:0}}>
                <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                  製品名：{row.attr.fingertipInfo.name}
                </Typography>
                <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                  製造者：{row.attr.fingertipInfo.producer}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}

      { (fingertipList.length===0) &&
        <Box sx={{my:3,width:"100%",textAlign:"center"}}>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
            {isLoading?"検索中...":"該当データがありません。"}
          </Typography>
        </Box>
      }
      </Grid>

    </Container>

  )
}