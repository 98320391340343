import {useContext, useEffect, useState} from "react";
import {Box, Container} from "@mui/material";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  DataStoreType,
  initialState,
  REGISTRATION_ACTION_TYPE
} from "../../contexts/WorkContext";
import useNotification from "../../../../utils/notificationUtil";
import BaseRegisterForm, {RegisterFormWorkAttr} from "../forms/RegisterForm";
import BaseEditForm, {EditFormWorkAttr} from "../forms/EditForm";
import ScrollToTop from "../../../../utils/ScrollTop";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {workAttrList} from "../../types/workForm";

type PrevStep = (payload?: Partial<DataStoreType['registPage']>) => void;

export interface RegisterWorkProps {
  prevStep: PrevStep;
}

export default function WorkForm(props: RegisterWorkProps) {
  const {state} = useContext(DataStoreContext);

  if (state.registPage.actionType === REGISTRATION_ACTION_TYPE.EDIT && !state.registPage.selectedWork) {
    props.prevStep();
    return null;
  }

  return (
    <>
      <ScrollToTop/>

      <Container>
        <Box mb={1} display='flex' justifyContent='flex-end'>
          <Pjdb1Button label="戻る" variant="contained" color="inherit" onClick={props.prevStep}/>
        </Box>
      </Container>

      {state.registPage.actionType === REGISTRATION_ACTION_TYPE.NEW && (
        <RegisterForm/>
      )}
      {state.registPage.actionType === REGISTRATION_ACTION_TYPE.EDIT && state.registPage.selectedWork && (
        <EditForm/>
      )}
    </>
  )
}

function RegisterForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg, errorMsg} = useNotification();
  const [inputs, setInputs] = useState<RegisterFormWorkAttr>(state.registPage.newFormData);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.REGIST,
      payload: {
        ...state.registPage,
        newFormData: inputs,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <BaseRegisterForm
      inputs={inputs}
      setInputs={setInputs}
      onClear={() => {
        dispatch({
          type: CONTEXT_ACTION_TYPE.REGIST,
          payload: {
            ...state.registPage,
            registered: false,
          }
        })
      }}
      validate={() => {
        if (state.registPage.registered) {
          errorMsg('すでに登録済みのため登録できません。登録する場合は、クリアボタンをクリックし入力内容をクリアしてください。');
          return false;
        }
        return true;
      }}
      onSuccess={() => {
        dispatch({
          type: CONTEXT_ACTION_TYPE.REGIST,
          payload: {
            ...state.registPage,
            registered: true,
          },
        })
        savedMsg();
        console.log("WorkForm: onSuccess");
      }}/>
  )
}

function EditForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg} = useNotification();
  const [inputs, setInputs] = useState<EditFormWorkAttr>(state.registPage.editFormData);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.REGIST,
      payload: {
        ...state.registPage,
        editFormData: inputs,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  if (!state.registPage.selectedWork) {
    throw new Error("Logic error: selectedWork is null");
  }

  return (
    <BaseEditForm
      inputs={inputs}
      setInputs={setInputs}
      work={state.registPage.selectedWork}
      onSuccess={() => {
        // 更新データをstateに反映
        const payload = {
          ...state.registPage,
          editFormData: {
            ...initialState.registPage.editFormData,
            [workAttrList.code.key]: state.registPage.selectedWork?.attr[workAttrList.code.key],
          },
        };
        setInputs(payload.editFormData);
        dispatch({
          type: CONTEXT_ACTION_TYPE.REGIST,
          payload,
        })
        savedMsg();
      }}
    />
  )
}
