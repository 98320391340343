/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './App.css';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import defaultTheme from './theme/theme';

import {HAND_URL_PREFIX,HAND_URLS,WORK_URL_PREFIX,WORK_URLS} from './constants/constants'

import configureAmplify from "./utils/awsConfigure";
import {setAuthLanguage, authFormFields, authComponents} from "./utils/authCustomization";

import ScrollToTop from './utils/ScrollTop';

import MainLayout from './templates/MainLayout';
import HandLayout from './features/handdb/templates/HandLayout';
import WorkLayout from "./features/workdb/templates/WorkLayout";
import Top from './pages/Top'
import HandList from './features/handdb/pages/HandList';
import HandDetail from './features/handdb/pages/HandDetail';
import FingertipList from './features/handdb/pages/FingertipList';
import FingertipDetail from './features/handdb/pages/FingertipDetail';
import HandFingertipRegiste from './features/handdb/pages/HandFingertipRegiste';
import WorkList from './features/workdb/pages/WorkList';
import WorkDetail from './features/workdb/pages/WorkDetail';
import WorkRegister from "./features/workdb/pages/WorkRegister";
import WorkRegisterList from "./features/workdb/pages/WorkRegisterList";
import NotFound from './pages/NotFound';

configureAmplify();
setAuthLanguage();

function App() {

  return (
    <>
      <ToastContainer />
      <Authenticator hideSignUp formFields={authFormFields} components={authComponents}>
        {({ signOut, user }) => (
          <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <Router>
              <ScrollToTop />
              <Routes>
                <Route path='/' element={<MainLayout bgcolor='#F6F7F8'/>}>
                  <Route index element={<Top />} />
                  <Route path="*" element={<NotFound />} />
                </Route>
                <Route path={String(HAND_URL_PREFIX)} element={<MainLayout />}>
                  <Route path={String(HAND_URLS.HAND_LIST.URL)} element={<HandLayout/>}>
                    <Route index element={<HandList/>} />
                  </Route>
                  <Route path={String(HAND_URLS.HAND_DETAIL.URL)} element={<HandLayout/>}>
                    <Route index element={<HandDetail/>} />
                  </Route>
                  <Route path={`${HAND_URLS.HAND_DETAIL.URL}/:${HAND_URLS.HAND_DETAIL.ARG}`} element={<HandLayout/>}>
                    <Route index element={<HandDetail/>} />
                  </Route>
                  <Route path={String(HAND_URLS.FINGERTIP_LIST.URL)} element={<HandLayout/>}>
                    <Route index element={<FingertipList/>} />
                  </Route>
                  <Route path={String(HAND_URLS.FINGERTIP_DETAIL.URL)} element={<HandLayout/>}>
                    <Route index element={<FingertipDetail/>} />
                  </Route>
                  <Route path={`${HAND_URLS.FINGERTIP_DETAIL.URL}/:${HAND_URLS.FINGERTIP_DETAIL.ARG}`} element={<HandLayout/>}>
                    <Route index element={<FingertipDetail/>} />
                  </Route>
                  <Route path={String(HAND_URLS.REGISTE.URL)} element={<HandLayout/>}>
                    <Route index element={<HandFingertipRegiste/>} />
                  </Route>
                  <Route path={`${HAND_URLS.REGISTE.URL}/:${HAND_URLS.REGISTE.ARG_MODE}/:${HAND_URLS.REGISTE.ARG_ID}`} element={<HandLayout/>}>
                    <Route index element={<HandFingertipRegiste/>} />
                  </Route>
                </Route>
                <Route path={String(WORK_URL_PREFIX)} element={<MainLayout />}>
                  <Route path={String(WORK_URLS.WORK_LIST.URL)} element={<WorkLayout/>}>
                    <Route index element={<WorkList/>} />
                  </Route>
                  <Route path={String(WORK_URLS.WORK_DETAIL.URL)} element={<WorkLayout/>}>
                    <Route index element={<WorkDetail/>} />
                  </Route>
                  <Route  path={`${WORK_URLS.WORK_DETAIL.URL}/:${WORK_URLS.WORK_DETAIL.ARG_CLASS_ID}`} element={<WorkLayout/>}>
                    <Route index element={<WorkDetail/>} />
                  </Route>
                  <Route path={String(WORK_URLS.WORK_REGISTER.URL)} element={<WorkLayout/>}>
                    <Route index element={<WorkRegister/>} />
                  </Route>
                  <Route path={String(WORK_URLS.WORK_REGISTER_LIST.URL)} element={<WorkLayout/>}>
                    <Route index element={<WorkRegisterList/>} />
                  </Route>
                </Route>
              </Routes>
            </Router>

          </ThemeProvider>
        )}
      </Authenticator>
    </>
  );
}

export default App;
