import {WorkSortOrderValue} from "../../../constants/constants";

/**
 * ハンド一覧検索条件
 */
export interface FormWorkSearchCond {
  keyword:string,
  listCount:number,
  sortOrder:WorkSortOrderValue,
}

/**
 * Work登録フォームステップ
 */
export const WORK_FORM_STEP = {
  WORK_SEARCH: 0,
  WORK_REGIST: 1,
};

export type WorkFormStep = typeof WORK_FORM_STEP[keyof typeof WORK_FORM_STEP];


/**
 * ワーク属性表用タイプ
 */
export interface AttrListRowType {
  key: string;
  label: string;
}

/**
 * ワーク属性表
 */
export const workAttrList = {
  nameJp: {key: "nameJp", label: "商品/部品名（日本語表記）"},
  nameEn: {key: "nameEn", label: "商品/部品名（英語表記）"},
  code: {key: "code", label: "JANコード/部品コード"},
  contentWeight: {key: "contentWeight", label: "内容重量[g]"},
  contentVolume: {key: "contentVolume", label: "内容容量[ℓ]"},
  description: {key: "description", label: "説明"},
  modelType: {key: "modelType", label: "モデル種別"},
  modelFile: {key: "modelFile", label: "モデルファイル"},
  fileType: {key: "fileType", label: "入力ファイル方式"},
  when: {key: "when", label: "測定日時"},
  who: {key: "who", label: "測定者"},

  classId: {key: "class-id", label: "クラスID"},
  instanceId: {key: "instance-id", label: "インスタンスID"},
  modelId: {key: "model-id", label: "モデルID"},

  created: {key: "created", label: "登録日時"}, // inserted と同じ値が入っているがとりあえず created で表示
  creator: {key: "creator", label: "登録者"},

  children: {key: "children", label: "子クラスのID"},
} as const satisfies Readonly<Record<string, AttrListRowType>>
