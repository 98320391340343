/* eslint-disable no-void */
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom'
import {
  Typography,
  Container,
  Box,
  Card,CardMedia,CardContent,
  Paper,
  TableContainer,Table,TableHead,TableRow,TableCell,TableBody
} from '@mui/material/';
import Grid from '@mui/material/Grid2';
import { useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import {HAND_URLS} from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE, initialState } from "../contexts/HandContext";

import {ResponseType, deleteFingertip, findHand} from '../../../utils/awsAmplifyUtil'
import { FingertipDocument, fingertipInitDoc , makeFingertipAttrList, makeFormFingertipAttrFromFingertipDocument } from '../types/pj1dbApiFingertip'
import { HandDocument } from '../types/pj1dbApiHand';
import { AttrListRowType } from '../types/handForm';

import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import UrdfLoader from '../components/UrdfLoader';

export default function FingertipDetail() {
  const { isModifiableRole } = useUserRole();
  const { deletedMsg, errorMsg } = useNotification();
  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();

  const { state, dispatch } = useContext(DataStoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const [info, setInfo] = useState<FingertipDocument>(fingertipInitDoc);
  const [fingertipAttrList, setFingertipAttrList] = useState<AttrListRowType[]>([]);
  const [dispImageType, setDispImageType] = useState<string>("image");

  interface ImageListRowType {
    key:string;
    url:string;
  }
  const [fingertipImageList, setFingertipImageList] = useState<ImageListRowType[]>([]);
  const [selectedFingertipImageKey, setSelectedFingertipImageKey] = useState<string>("");

  const [handList, setHandList] = useState<HandDocument[]>([]);

  const navigate = useNavigate();
  const urlParams = useParams<{ fingertipId: string }>();

  const imageStyle = {
    border : "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border : "2px solid #48B130",
  }

  useEffect(()=>{
    let tempInfo:FingertipDocument;
    if(urlParams.fingertipId===state.fingertipDetailPage.info.attr.fingertipId){
      tempInfo = state.fingertipDetailPage.info;
    }else if (!urlParams.fingertipId){
      tempInfo = state.fingertipDetailPage.info;
    }else{
      [tempInfo] =
        state.fingertipListPage.fingertipList.filter(
          (item)=>item.attr.fingertipId === urlParams.fingertipId
        );
    }
    if(tempInfo!==undefined){
      setInfo(tempInfo);
    }else{
      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.FINGERTIP_LIST.TAB_IDX });
      navigate(`${HAND_URLS.FINGERTIP_LIST.URL}`);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const findHandProc = async () => {
    try {
      const cond = {
        "query":{
          "handId":{ "$in" : info.attr.handIds }
        }
      };
      const result = await findHand(cond);
      if(!result.isSuccess) return;
      if(result.data && Object.entries(result.data).length > 0){
        let handListData:HandDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
        handListData = handListData.sort((a,b) => a.attr.handInfo.name.localeCompare(b.attr.handInfo.name));
        setHandList(handListData);
      }else{
        setHandList([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if(info.attr.fingertipId==="") return;

    // 爪属性表
    setFingertipAttrList(makeFingertipAttrList(info,false));

    // 画像リストを作成
    if(info.url){
      setFingertipImageList(
        info.url?.image.map((imageUrl,idx)=>(
          {key:`image${idx}`,url:imageUrl}
        ))
      );
      setSelectedFingertipImageKey("image0");
    }

    // 接続可能ハンドを検索
    void findHandProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[info]);

  const deleteData = async () => {
    try {
      console.log("deleteData",info.attr.fingertipId);
      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      setIsLoading(true);

      const delData = {
        id:info.attr.fingertipId
      }
      const resDel:ResponseType<string> = await deleteFingertip(delData);
      if(!resDel.isSuccess){
        errorMsg("削除処理が失敗しました");
        return;
      }

      deletedMsg();

      dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_DETAIL ,
        payload: {info: initialState.fingertipDetailPage.info} });

      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.FINGERTIP_LIST.TAB_IDX });
      navigate(`${HAND_URLS.FINGERTIP_LIST.URL}`);

    } catch (error) {
      console.log("deleteData Error : ", error);
      errorMsg("削除処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }
  const editData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regModeFingertip:false,fingertipPanel:makeFormFingertipAttrFromFingertipDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.REGISTE.TAB_IDX });
    navigate(`${HAND_URLS.REGISTE.URL}/fingertip/${info.attr.fingertipId}`);
  }
  const copyData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regModeFingertip:true,fingertipPanel:makeFormFingertipAttrFromFingertipDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.REGISTE.TAB_IDX });
    navigate(`${HAND_URLS.REGISTE.URL}/copyFingertip/${info.attr.fingertipId}`);
  }

  return (
    <>
      <Container maxWidth="lg" sx={{maxWidth:"1500px !important"}}>
        <Card sx={{p:3,mt:2}}>

          <Grid container spacing={3} alignItems="flex-end" >
            <Grid>
              <Typography variant="h6">爪詳細</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              <Typography variant="body2" sx={{color:"gray"}}>
                登録日時（登録者）：{`${info.attr?.registrationDate?new Date(info.attr?.registrationDate).toLocaleString():""}（${info.attr.registrant}）`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
            <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>

              <Pjdb1RadioGroup
                name="dispImageType" value={dispImageType}
                onChange={(e)=>(setDispImageType(e.target.value))}
                radios={[
                  {btnKey:"image",btnLbl:"画像"},
                  {btnKey:"model",btnLbl:"モデル"},
                ]}
                disabledRadios={info.url?.urdf && info.url?.urdf.length > 0 ? [] : ["model"]}
              />

              <Grid container spacing={2} alignItems="flex-start" sx={{mt:0}}>
                { dispImageType === "image" &&
                  <>
                    <Grid size={{xs:3,md:2}}
                    sx={{pt:"0px !important",pr:"1px !important",height:"50vw",maxHeight:"70vh",overflowY:"auto",overflowX:"hidden"}}
                    className="thin-scroll-bar">
                      {fingertipImageList.map(row =>(
                        <Box key={row.key}>
                          <a href="" onClick={(e)=>{
                            e.preventDefault();
                            setSelectedFingertipImageKey(row.key);
                          }}>
                            <img src={row.url} className="square-image" style={row.key === selectedFingertipImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                          </a>
                        </Box>
                      ))}
                    </Grid>
                    <Grid size={{xs:9,md:10}} sx={{pt:"0px !important"}}>
                      <img src={fingertipImageList.find((row)=>(row.key===selectedFingertipImageKey))?.url} style={imageStyle} className="square-image" alt="" />
                    </Grid>
                  </>
                }
                { dispImageType === "model" &&
                  <Grid sx={{p:"0px !important",border:"1px solid rgba(0, 0, 0, 0.23)"}} className="square-image">
                    <UrdfLoader urdfUrl={String(info.url?.urdf[0])} />
                  </Grid>
                }
              </Grid>

            </Grid>
            <Grid size={{xs:12,md:5}} sx={{pt:"20px !important"}}>

              <Typography variant="subtitle1">爪属性表</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                  <TableHead sx={{bgcolor:"#EEE"}}>
                    <TableRow>
                      <TableCell sx={{px:1,py:0.5}}>分類</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fingertipAttrList.map((row) => (
                      <TableRow
                        key={row.key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width={80} sx={{px:1,py:0.5}}>{row.class}</TableCell>
                        <TableCell width={150} sx={{px:1,py:0.5}}>{row.attr}</TableCell>
                        <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>

          <Grid container spacing={3} alignItems="flex-end" sx={{mt:1}}>
            <Grid sx={{pt:"8px !important"}}>
              <Typography variant="h6">接続可能ハンド一覧</Typography>
            </Grid>
          </Grid>

          { handList.length === 0 &&
            <Typography variant="subtitle1" sx={{m:2,fontWeight:"bold"}}>
              接続可能爪がありません。
            </Typography>
          }
          { handList.length > 0 &&
            <Grid container spacing={3} alignItems="flex-start" sx={{mt:1,m:2}}>
              {handList.map(row =>(
                <Grid key={row.attr.handId} >
                  <Link to={`${HAND_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} style={{ textDecoration:'none' }}
                  onClick={()=>{
                    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: HAND_URLS.HAND_DETAIL.TAB_IDX });
                    dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:row} });
                  }}>

                    <Card sx={{ width:240, height:200 }}>
                      <CardMedia
                        component="img"
                        height="145"
                        image={row.url?.image[0]}
                        alt={row.attr.handInfo.name}
                      />
                      <CardContent sx={{py:0.5,px:1,m:0}}>
                        <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                          製品名：{row.attr.handInfo.name}
                        </Typography>
                        <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                          製造者：{row.attr.handInfo.producer}
                        </Typography>
                      </CardContent>
                    </Card>

                  </Link>
                </Grid>
              ))}
            </Grid>
          }
        </Card>
      </Container>
      { isModifiableRole &&
        <Box component="section" sx={{ textAlign:"center", mt:2 }}>
          <Pjdb1Button
            label="登録情報を変更"
            variant="contained"
            color="primary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={editData}
            disabled={info.attr.fingertipId===""}
          />

          <Pjdb1Button
            label="登録情報を削除"
            variant="contained"
            color="error"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={()=>{void deleteData();}}
            disabled={info.attr.fingertipId===""}
          />

          <Pjdb1Button
            label="コピー登録"
            variant="contained"
            color="secondary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={copyData}
            disabled={info.attr.fingertipId===""}
          />
        </Box>
      }

      <ConfirmDialog
        title="削除確認"
        message="爪情報を削除しますか？"
        confirmButton="削除"
        confirmButtonStyle="error"
      />
    </>

  )
}